import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { StoreSelector } from './components/StoreSelector';
import { DeliveryMethodSelect } from './components/DeliveryMethodSelect';
import { SourceTabs } from './components/SourceTabs';
import { SourceScheduleC } from './components/SourceSchedule';
import { useSlotsManagement } from 'hooks/useSlotsManagement';
import { FaRegSave } from "react-icons/fa";
import { SlotsData, Source } from 'interfaces/ISlots';
import usePreRender from 'hooks/usePreRender';
import { cn, removeEmptyKeys } from 'utils/utils';
import useLogisticSourcesResources from 'hooks/entities/useLogisticSourcesResources';
import Swal from 'sweetalert2';

interface SlotsEditorProps {
  sourceInitList: Array<Source>
  onLoadMore: () => void;
  onFilter: (search: string) => void;
}

export const SlotsEditor: React.FC<SlotsEditorProps> = ({
  sourceInitList,
  onLoadMore,
  onFilter
}) => {
  const { _color } = usePreRender()
  const [activeSource, setActiveSource] = useState<string>("");
  const [deliveryMethod, setDeliveryMethod] = useState(0);
  const { set_loading_app, put_upsert_source_action } = useLogisticSourcesResources()

  const form = useForm<SlotsData>({
    defaultValues: {}
  });

  const { selectedStores, sources, handleStoreChange, getSourceIndex, getSourceDeliveryMethods } = useSlotsManagement(form);

  // Set initial active source when stores are selected
  useEffect(() => {    
    if (selectedStores.length > 0 && !activeSource) {
      setActiveSource(selectedStores[0]._id);
    } else if (selectedStores.length === 0) {
      setActiveSource('');
    } else if (!selectedStores.find((store:any) => store._id === activeSource)) {
      setActiveSource(selectedStores[0]._id);
    }
    let storeData = selectedStores.find((sourceData:any) => sourceData._id === activeSource);
    if(storeData){
      setDeliveryMethod(0);
    }
  }, [sources,  selectedStores, activeSource]);

  const handleSubmit = async (data: SlotsData) => {
    try {
      set_loading_app(true)
      let selectedStores = form.getValues('selectedStores');
      let sourcesToEdit = selectedStores.map((source:any) => {
        source.id = source._id;
        delete source._id;
        delete source.ownerId;
        source.schedule.map((schedule:any) => {
          if(Array.isArray(schedule.deliverySlots)){
            let obj = schedule.deliverySlots.reduce(function(acc:any, cur:any, i:any) {
              acc[i] = cur;
              return acc;
            }, {});
            schedule.deliverySlots = obj;
          }
          if(schedule.slots){
            delete schedule.slots;
          }
          schedule.deliverySlots = removeEmptyKeys(schedule.deliverySlots);
          return schedule;
        });
        return source;
      });
      const res: any = await put_upsert_source_action({ body: [...sourcesToEdit] })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Horarios editados para los sources" ,
          icon: "success",
        })
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  };

  const handleDeliveryMethodChange = (method: number) => {
    setDeliveryMethod(method);
  };

  const handleSourceChange = (sourceId: string) => {
    setDeliveryMethod(0);
    setActiveSource(sourceId);
  };

  const handleCopySource = (fromSourceId: string, toSourceId: string) => {
    const fromSourceIndex = getSourceIndex(fromSourceId);
    const toSourceIndex = getSourceIndex(toSourceId);
    
    if (fromSourceIndex !== -1 && toSourceIndex !== -1) {
      const sourceSchedule = form.getValues(`selectedStores.${fromSourceIndex}.schedule`);
      form.setValue(`selectedStores.${toSourceIndex}.schedule`, { ...sourceSchedule });
    }
  };
  
  const handleSourceLoadMore = () => {
    onLoadMore()
  };

  const handleSourceFilter = (search: string) => {
    onFilter(search)
  };


  useEffect(() => {
    form.setValue(`sources`, sourceInitList);
  },
  // eslint-disable-next-line 
  [sourceInitList]);

  
  return (
    <div className="min-h-screen">
      <div>
        <form id="schedule-form" onSubmit={form.handleSubmit(handleSubmit)} className="flex-1">
        </form>
        <div className="space-y-4 mb-6">
          <div className="flex flex-row gap-3 items-center mb-6">
            <div className="min-w-[90%]">
              <StoreSelector
                sources={sourceInitList}
                selectedStores={selectedStores}
                onStoreChange={handleStoreChange}
                onFilterChange={handleSourceFilter}
              />
            </div>
            <div className="flex justify-center items-top min-h-[100%] min-w-[10%] overflow-hidden">
              <button
                type="submit"
                form="schedule-form"
                className={cn(
                  "inline-flex items-center gap-2 px-6 py-2.5 rounded-lg transition-colors font-medium max-h-[35px]",
                  `text_${_color}`,
                )}
              >
                <FaRegSave size={20} />
                Guardar
              </button>
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 flex overflow-x-auto">
          {activeSource && selectedStores.length > 0 && (
            <SourceTabs
              sources={selectedStores}
              activeSource={activeSource}
              onSourceChange={handleSourceChange}
              onLoadMore={handleSourceLoadMore}
              onCopySource={handleCopySource}
            />
          )}

        {activeSource && selectedStores.length > 0 && (
          selectedStores.map((sourceS, indexS) => (
            <div 
              key={indexS}
              className={cn(
                'p-2 flex flex-col',
                getSourceIndex(activeSource) === indexS ? 'block' : 'hidden'
              )}
              >

              
              <div className="space-y-4 mb-6">
                {getSourceIndex(activeSource) === indexS && activeSource && getSourceDeliveryMethods(activeSource) && (<DeliveryMethodSelect
                  list={getSourceDeliveryMethods(activeSource)}
                  value={deliveryMethod}
                  onChange={handleDeliveryMethodChange}
                />)}
              </div>

              {getSourceIndex(activeSource) === indexS && activeSource && getSourceDeliveryMethods(activeSource).map((deliveryM, index) => (
                <div
                  key={index}
                  className={deliveryMethod === index ? 'block' : 'hidden'}
                >
                  <SourceScheduleC
                    sourceIndex={indexS}
                    deliveryIndex={index}
                    form={form}
                  />
                </div>
              ))}
              {getSourceIndex(activeSource) === indexS && activeSource && (getSourceDeliveryMethods(activeSource)?.length ?? 0) === 0 && (
                <div className="text-center py-6 px-3 text-gray-500 text-sm">
                  Ningun tipo de entrega disponible
                </div>
              )}
            
            </div>
          ))
        )}

        {!activeSource  && (
          <div className="text-center py-6 px-3 text-gray-500 text-sm">
            Ninguna tienda ha sido añadida
          </div>
        )}

        </div>
      </div>
    </div>
  );
}