import { Env, MultiClient } from "config"
import { CLIENTS_AUTH0 } from "constants/AUTH0_CONSTANTS"

interface IAuth0Credentials {
  clientId: string
  audience: string
  domain: string
}

const useAuth0Credentials = (): IAuth0Credentials => {
  const [_client, _env] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  const credentials = {
    clientId: CLIENTS_AUTH0[_client][_env].clientId || "",
    audience: CLIENTS_AUTH0[_client][_env].audience || "",
    domain: CLIENTS_AUTH0[_client][_env].domain || "",
  }

  return { ...credentials }
}

export default useAuth0Credentials
