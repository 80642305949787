import { AddButton, Select, Switch, TextField, TrashButton } from "components/Core"
import { Controller, useFieldArray } from "react-hook-form"
import { DAYS_OF_WEEK_NUMBER, HOURS_OF_DAY, SHIPPING_TYPES, UNIT_TIME } from "constants/FORM_CONSTANTS"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"

interface IScheduleFields {
  index: number
  control: any
  schedule: any
  errors: any
  watch: any
  delivery_type: any
}

const ScheduleFields = ({ index, control, schedule, errors, delivery_type, watch }: IScheduleFields) => {
  const remove_schedule = () => schedule.remove(index)
  const slots = useFieldArray({ control, name: `schedule[${index}].deliverySlots` })
  // const remove_slot = (_i: any) => slots.remove(_i)
  const add_slot = () => slots?.append({ from: "", to: "", label: "", status: true, capacity: 0 })
  const has_enable_time = watch(`schedule[${index}].has_enable_time`)

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full flex flex-row items-center justify-between p-2">
        <h4 className="font-semibold">Agendamiento {Number(index + 1)}</h4>
        <TrashButton onClick={remove_schedule} />
      </div>
      <div className="w-full border-b border-[#919191]"></div>
      <div className="w-full flex flex-col lg:flex-row gap-4">
        <Controller
          name={`schedule[${index}].deliveryType`}
          defaultValue={schedule.fields[index].deliveryType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select label="Tipo de entrega" options={delivery_type} onChange={onChange} value={value} />
          )}
        />
        <Controller
          name={`schedule[${index}].shippingType`}
          defaultValue={schedule.fields[index].shippingType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select label="Tipo de despacho" multi options={SHIPPING_TYPES} onChange={onChange} value={value} />
          )}
        />
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-start gap-4">
        <Controller
          name={`schedule[${index}].disabled`}
          defaultValue={schedule.fields[index].disabled}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch label="Desactivar método de agendamiento" onChange={onChange} checked={value} />
          )}
        />
        <Controller
          name={`schedule[${index}].has_enable_time`}
          control={control}
          defaultValue={schedule.fields[index].has_enable_time}
          render={({ field: { onChange, value } }) => (
            <Switch label="Habilitar por rango de horario" onChange={onChange} checked={value} />
          )}
        />
      </div>
      {has_enable_time ? (
        <>
          <div className="w-full flex flex-row items-center justify-start px-2">
            <h4 className="">Rango de horario de agendamiento </h4>
          </div>
          <div className="w-full border-b border-[#919191]"></div>
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name={`schedule[${index}].enableTime.from`}
              defaultValue={schedule.fields[index].enableTime?.from}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Inicio" options={HOURS_OF_DAY} onChange={onChange} value={value} />
              )}
            />
            <Controller
              name={`schedule[${index}].enableTime.to`}
              defaultValue={schedule.fields[index].enableTime?.to}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Fín" options={HOURS_OF_DAY} onChange={onChange} value={value} />
              )}
            />
          </div>
        </>
      ) : null}
      <Controller
        name={`schedule[${index}].productType`}
        defaultValue={schedule.fields[index].productType}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField label="Tipo de producto" onChange={onChange} value={value} />
        )}
      />
      <Controller
        name={`schedule[${index}].coverageRadius`}
        defaultValue={schedule.fields[index].coverageRadius}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField label="Radio de cobertura en metros" type="number" onChange={onChange} value={value} />
        )}
      />
      <div className="w-full flex flex-row items-center justify-start px-2">
        <h4 className="">Tiempo de preparación en tienda </h4>
      </div>
      <div className="w-full border-b border-[#919191]"></div>
      <div className="w-full flex flex-col lg:flex-row gap-4">
        <Controller
          name={`schedule[${index}].leadtime.value`}
          defaultValue={schedule.fields[index].leadtime.value}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField label="Cantidad de tiempo (leadtime)" type="number" onChange={onChange} value={value} />
          )}
        />
        <Controller
          name={`schedule[${index}].leadtime.unit`}
          defaultValue={schedule.fields[index].leadtime.unit}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select label="Unidad de tiempo (leadtime)" options={UNIT_TIME} onChange={onChange} value={value} />
          )}
        />
      </div>
      <div className="w-full flex flex-row items-center justify-between px-2">
        <h4 className="">Slots horarios de entrega </h4>
        <AddButton label="Agregar Slot" onClick={add_slot} />
      </div>
      <div className="w-full border-b border-[#919191]"></div>
      {(slots?.fields || [])?.map((slot, _i) => {
        return (
          <div
            key={slot?.id}
            className="w-full flex flex-col items-center justify-between lg:justify-center gap-4 px-4"
          >
            <div className="w-full flex flex-row justify-between px-2">
              <h4 className="">Slot {_i + 1} </h4>
              <TrashButton onClick={() => slots.remove(_i)} />
            </div>
            <div className="w-full flex flex-col lg:flex-row gap-4">
              <Controller
                name={`schedule[${index}].deliverySlots[${_i}].dayOfWeek`}
                defaultValue={schedule?.fields[index]?.deliverySlots[_i]?.dayOfWeek}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select label="Dia de la semana" options={DAYS_OF_WEEK_NUMBER} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name={`schedule[${index}].deliverySlots[${_i}].label`}
                defaultValue={schedule?.fields[index]?.deliverySlots[_i]?.label}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField label="Etiqueta slot" onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className="w-full flex flex-row items-center justify-start px-2">
              <h4 className="">Rango de horario </h4>
            </div>
            <div className="w-full flex flex-col lg:flex-row gap-4">
              <Controller
                name={`schedule[${index}].deliverySlots[${_i}].from`}
                defaultValue={schedule?.fields[index]?.deliverySlots[_i]?.from}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select label="Inicio" options={HOURS_OF_DAY} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name={`schedule[${index}].deliverySlots[${_i}].to`}
                defaultValue={schedule?.fields[index]?.deliverySlots[_i]?.to}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select label="Fín" options={HOURS_OF_DAY} onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className="w-full flex flex-col lg:flex-row gap-4">
              <Controller
                name={`schedule[${index}].deliverySlots[${_i}].capacity`}
                defaultValue={schedule?.fields[index]?.deliverySlots[_i]?.capacity}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField label="Capacidad" type="number" onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className="w-full flex flex-col lg:flex-row justify-between gap-4">
              <Controller
                name={`schedule[${index}].deliverySlots[${_i}].status`}
                defaultValue={schedule?.fields[index]?.deliverySlots[_i]?.status}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch label="Habilitado" onChange={onChange} checked={value} />
                )}
              />
            </div>
            <div className="w-4/5 border-b border-[#919191]"></div>
          </div>
        )
      })}
      {Boolean(errors?.schedule && errors?.schedule[index]) ? <BadgeAlertForm /> : null}
    </div>
  )
}

export default ScheduleFields
