import { Navigate, Route, Routes } from "react-router-dom"
import NotFoundScreen from "pages/NotFoundScreen"
import { CLIENT } from "config/init"
import { Env, MultiClient } from "config"
import Layout from "components/Core/Layout"

import routes_core from "router/listRoutes"
import routes_entel from "overrides/theme/entel/base/router/listRoutes"
import routes_entel_peru from "overrides/theme/entelperu/base/router/listRoutes"
import routes_wom from "overrides/theme/wom/base/router/listRoutes"

import { QueryClientProvider } from "@tanstack/react-query"
import { queryClient } from "screens/Alertas/lib/queryClient"

const PrivateRouter = () => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  const getClientConfig = (group: CLIENT) => {
    switch (group) {
      case CLIENT.WOM:
        return routes_wom
      case CLIENT.ENTEL:
        return routes_entel
      case CLIENT.ENTEL_PERU:
        return routes_entel_peru
      default:
        return routes_core
    }
  }

  let routeList = getClientConfig(_client)

  return (
    <Layout>
      <Routes>
        {routeList?.map((route: any, i: number) => {
          const Element = route?.element

          // Envuelve solo la ruta específica con QueryClientProvider
          if (route.name === "StateAlerts") {
            return (
              <Route
                key={i}
                path={route?.path}
                element={
                  <QueryClientProvider client={queryClient}>
                    <Element />
                  </QueryClientProvider>
                }
              />
            )
          }

          return <Route key={i} path={route?.path} element={<Element />} />
        })}
        <Route path="404" element={<NotFoundScreen />} />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Layout>
  )
}

export default PrivateRouter
