// Types for the alerts system
export interface Order {
  orderCreationDate: string | Date;
  id: string;
  date: string;
  state: string;
  description: string;
  delivery_type: string;
  shipping_type: string;
  courier: string;
  classification: "on_track" | "warning" | "critical";
}

export type SLAState = {
  state: string;
  description: string;
  unit: string;
  sla: number;
  warning: string;
  critical: string;
}

export interface FilterOptionType {
  value: string;
  label: string;
}

export interface FilterConfig {
  id: string;
  title: string;
  placeholder: string;
  options: FilterOptionType[];
  multiple: boolean;
}

export interface FilterOptions {
  delivery_types: FilterConfig;
  sla_states: FilterConfig;
  shipping_types: FilterConfig;
  couriers: FilterConfig;
}

export interface FilterState {
  delivery_type: string[];
  shipping_type: string[];
  courier: string[];
  classification: string[];
  source_id: string;
  source_name: string;
  orderCreationDate: {
    startDate: Date;
    endDate: Date;
  };
  [key: string]:
    | string
    | string[]
    | { startDate: Date; endDate: Date }
    | undefined;
}

export const DEFAULT_FILTERS: FilterState = {
  delivery_type: [],
  shipping_type: [],
  courier: [],
  classification: [],
  source_id: "",
  source_name: "",
  orderCreationDate: {
    startDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  },
};

export interface StateData {
  state: string;
  description: string;
  unit: "minute" | "hour" | "day" | "week";
  onTrack: number;
  warningCount: number;
  criticalCount: number;
  totalOrders: number;
}

export interface Summary {
  totalOrders: number;
  onTrack: number;
  warning: number;
  critical: number;
}

export interface ChartData {
  name: string;
  onTrack: number;
  warning: number;
  critical: number;
}

export interface OrdersStatsResponse {
  summary: Summary;
  stateDistribution: StateData[];
  distributionData: ChartData[];
  orders: Order[];
  totalItems: number;
}

export interface ExportMessage {
  totalItems: number;
  orders: any[];
}


export const API_ENDPOINTS = {
  stats: "/api/orders/stats",
  export: "/api/orders/export",
  filters: "/api/filters",
} as const;