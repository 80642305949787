import PublicRouter from "router/PublicRouter"
import { PublicProvider } from "store/context/PublicContext"
import AppProvider from "store/providers/AppProvider"
import { Flowbite } from "flowbite-react"
import get_flowbite_theme from "utils/get_flowbite_theme/base"
import { Auth0Provider } from "@auth0/auth0-react"
import useAuth0Credentials from "hooks/useAuth0Credentials"
import "assets/css/global.css"
import "webcomponent/JsonViewer/index"

const AppContainer = () => {
  const { clientId, domain } = useAuth0Credentials()

  return (
    <Flowbite theme={{ theme: get_flowbite_theme }}>
      <Auth0Provider
        clientId={clientId}
        domain={domain}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/callback`,
        }}
      >
        <PublicProvider>
          <AppProvider>
            <PublicRouter />
          </AppProvider>
        </PublicProvider>
      </Auth0Provider>
    </Flowbite>
  )
}

export default AppContainer
