import { useContext, useState } from "react"
import { authorizerService } from "services/middleware_services"
import AuthContext from "store/context/PublicContext"
import { AppContext } from "store/context/AppContext"
import useCancelToken from "hooks/useCancelToken"
import { SIMO_VAR, SIMO_VAR_POSTRENDER, SIMO_VAR_PRERENDER } from "constants/APP_CONSTANTS"
import { logInAction, logOutAction } from "store/actions/publicActions"
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import useAuth0Credentials from "hooks/useAuth0Credentials"

interface IErrorLogin {
  code: number
  message: string
}

const useAuthorizer = () => {
  const { logout } = useAuth0()
  const [error_login, set_error_login] = useState<IErrorLogin>({
    code: 0,
    message: "",
  })
  const { isCancel } = useCancelToken()
  const { dispatch } = useContext(AuthContext)
  const { set_loading_app } = useContext(AppContext)
  const navigate = useNavigate()
  const { clientId, audience } = useAuth0Credentials()

  const login_user_action = async () => {
    try {
      set_loading_app(true)
      set_error_login({
        code: 0,
        message: "",
      })

      const credentials = {
        audience: audience,
        grant_type: "client_credentials",
        client_id: clientId,
      }

      const responseLogin = await authorizerService(credentials)
      const {
        data: { access_token },
      } = responseLogin
      dispatch(logInAction({ id: "guest", name: "Invitado", role: "guest", email: "guest", token: access_token }))
      set_loading_app(false)
      navigate("/dashboard")
    } catch (error: any) {
      set_loading_app(false)
      set_error_login({
        code: error?.response?.status,
        message: error.response?.data?.message,
      })
      if (isCancel(error)) return
    }
  }

  const logout_user_action = () => {
    localStorage.removeItem(SIMO_VAR)
    localStorage.removeItem(SIMO_VAR_PRERENDER)
    localStorage.removeItem(SIMO_VAR_POSTRENDER)
    dispatch(logOutAction)
    logout().then(() => window.location.reload())
  }

  return {
    error_login,
    login_user_action,
    logout_user_action,
  }
}

export default useAuthorizer
