import { FormSection, Select, Switch, TextField, SearchableSelect } from "components/Core"
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { Controller } from "react-hook-form"
import PreviewData from "./PreviewData"
import LoadCriterias from "./LoadCriterias"
import { ROUTE_TYPE } from "./route.config"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"

interface IFormRoute {
  control: any
  watch: any
  setValue: any
  edit?: boolean
  route?: any
  errors: any
}

const FormRoute = forwardRef(
  ({ control, watch, route, edit, setValue, errors }: IFormRoute, ref: ForwardedRef<any>) => {
    const { action_search_osrm_action } = useSearchMiddlewareOSRM({})
    const [newLocationDataSource, setNewLocationDataSource] = useState(false)
    const [newLocationDataTarget, setNewLocationDataTarget] = useState(false)
    const [options, setOptions] = useState<any>([])
    const [isSourceLocation, setIsSourceLocation] = useState(false)
    const [isTargetLocation, setIsTargetLocation] = useState(false)
    const [isLoadingSearch, setIsLoadingSearch] = useState(false)
    const [editSourceOnLoad, setEditSourceOnLoad] = useState(false)
    const [editTargetOnLoad, setEditTargetOnLoad] = useState(false)

    const values = watch()
    const sourceType = watch("source.type")
    const targetType = watch("target.type")

    useEffect(() => {
      setOptions([])
      if (sourceType === "Location") {
        setIsSourceLocation(true)
      } else {
        setIsSourceLocation(false)
      }
      setValue("source.data", {})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceType])

    useEffect(() => {
      setOptions([])
      if (targetType === "Location") {
        setIsTargetLocation(true)
      } else {
        setIsTargetLocation(false)
      }
      setValue("target.data", {})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetType])

    useEffect(() => {
      if (sourceType === "Source") {
        setNewLocationDataSource(false)
        setNewLocationDataSource(true)
      }
      if (targetType === "Source") {
        setNewLocationDataTarget(false)
        setNewLocationDataTarget(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceType, targetType])

    useEffect(() => {
      if (!edit) {
        setNewLocationDataSource(true)
        setNewLocationDataTarget(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])

    useImperativeHandle(ref, () => ({
      handleEditSourceOnLoad: (_val: boolean) => setEditSourceOnLoad(_val),
      handleEditTargetOnLoad: (_val: boolean) => setEditTargetOnLoad(_val),
    }))

    const getSourcesAction = async (queryString: string) => {
      let params = { skipRecords: 0, maxRecords: 100, queryString, fields: ["id", "name", "aliases"] }
      try {
        setOptions([])
        setIsLoadingSearch(true)
        let res: any = await action_search_osrm_action("sources", params)
        if (Boolean(res?.data?.message?.total)) {
          let result = res?.data?.message?.records.map((src: any) => ({
            id: src?.id,
            name: src?.name,
            additionalInfo: { ...src },
          }))
          setOptions(result)
          setIsLoadingSearch(false)
          return result
        } else {
          setIsLoadingSearch(false)
          return []
        }
      } catch (error) {
        setIsLoadingSearch(false)
        console.log("Error", JSON.stringify(error))
      }
    }

    const validationsSectionsErrors = {
      1: Boolean(["id", "status"].some((key: string) => Object.keys(errors).includes(key))),
      2: Boolean(["source"].some((key: string) => Object.keys(errors).includes(key))),
      3: Boolean(["target"].some((key: string) => Object.keys(errors).includes(key))),
    }

    return (
      <div className="w-full flex flex-col items-center gap-4">
        <FormSection
          title="Información general"
          helperText="Información general"
          number={1}
          anyError={validationsSectionsErrors[1]}
        >
          <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
            <Controller
              name="id"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Id Ruta" onChange={onChange} value={value} disabled={Boolean(edit)} />
              )}
            />
            <div className="w-full flex flex-col lg:flex-row gap-4">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Switch label="Estado de ruta" onChange={onChange} checked={value} />
                )}
              />
            </div>
            {validationsSectionsErrors[1] ? <BadgeAlertForm /> : null}
          </div>
        </FormSection>
        <FormSection title="Source" helperText="Source" number={2} anyError={validationsSectionsErrors[2]}>
          <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
            <Controller
              name="source.type"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo" options={ROUTE_TYPE} onChange={onChange} value={value} />
              )}
            />
            {edit && isSourceLocation && !newLocationDataSource ? (
              <>
                <PreviewData control={control} type="source" />
                <div className="w-full flex items-center justify-end">
                  <p onClick={() => setNewLocationDataSource(true)} className="text-[#F9004D] underline cursor-pointer">
                    Limpiar
                  </p>
                </div>
              </>
            ) : isSourceLocation ? (
              <LoadCriterias type="source" control={control} setValue={setValue} watch={watch} />
            ) : (editSourceOnLoad && route?.source?.data?.name) || values?.source?.data?.name ? (
              <>
                <TextField
                  label="Source"
                  value={`${values?.source?.data?.name || route?.source?.data?.name} (${
                    values?.source?.data?.id || route?.source?.data?.id
                  })`}
                  disabled
                />
                <div className="w-full flex items-center justify-end">
                  <p
                    onClick={() => {
                      setValue("source.data", {})
                      setEditSourceOnLoad(false)
                    }}
                    className="text-[#F9004D] underline cursor-pointer"
                  >
                    Limpiar
                  </p>
                </div>
              </>
            ) : (
              <SearchableSelect
                name="source.data"
                control={control}
                options={options}
                onSearch={getSourcesAction}
                placeholder="Source"
                isLoading={isLoadingSearch}
                seeLabelWithId
              />
            )}
            {validationsSectionsErrors[2] ? <BadgeAlertForm /> : null}
          </div>
        </FormSection>
        <FormSection title="Target" helperText="Target" number={3} anyError={validationsSectionsErrors[3]}>
          <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
            <Controller
              name="target.type"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo" options={ROUTE_TYPE} onChange={onChange} value={value} />
              )}
            />
            {edit && isTargetLocation && !newLocationDataTarget ? (
              <>
                <PreviewData control={control} type="target" />
                <div className="w-full flex items-center justify-end">
                  <p onClick={() => setNewLocationDataTarget(true)} className="text-[#F9004D] underline cursor-pointer">
                    Limpiar
                  </p>
                </div>
              </>
            ) : isTargetLocation ? (
              <LoadCriterias type="target" control={control} setValue={setValue} watch={watch} />
            ) : (editTargetOnLoad && route?.target?.data?.name) || values?.target?.data?.id ? (
              <>
                <TextField
                  label="target"
                  value={`${values?.target?.data?.name || route?.target?.data?.name} (${
                    values?.target?.data?.id || route?.target?.data?.id
                  })`}
                  disabled
                />
                <div className="w-full flex items-center justify-end">
                  <p
                    onClick={() => {
                      setValue("target.data", {})
                      setEditTargetOnLoad(false)
                    }}
                    className="text-[#F9004D] underline cursor-pointer"
                  >
                    Limpiar
                  </p>
                </div>
              </>
            ) : (
              <SearchableSelect
                name="target.data"
                control={control}
                options={options}
                onSearch={getSourcesAction}
                placeholder="Target"
                isLoading={isLoadingSearch}
              />
            )}
            {validationsSectionsErrors[3] ? <BadgeAlertForm /> : null}
          </div>
        </FormSection>
      </div>
    )
  },
)

export default FormRoute
