import defaultTranslate from "./locales/es_translation.json"

const getTranslate = (word: string, fileTranslated: any) => {
  const _defaultTranslate: any = defaultTranslate
  if (fileTranslated.hasOwnProperty(word)) {
    return fileTranslated[word]
  } else {
    return _defaultTranslate[word] || word
  }
}

const _translate = (key: string) => {
  return getTranslate(key, defaultTranslate)
}

export default _translate