import cn from "classnames";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import usePreRender from "hooks/usePreRender";

interface IButtonGroup {
  disabled?: boolean;
  onSubmit?: () => void;
  isLoading?: boolean;
  cancelText?: string;
  saveText?: string;
}

const ButtonGroup = ({
  disabled = false,
  onSubmit,
  isLoading = false,
  cancelText = "Cancelar",
  saveText = "Guardar",
}: IButtonGroup) => {
  const { _color } = usePreRender();
  const navigate = useNavigate();

  const handle_back = useCallback(() => navigate(-1), [navigate]);
  const handle_save = useCallback(() => {
    if (onSubmit) onSubmit();
  }, [onSubmit]);

  return (
    <div className="flex flex-row gap-2 font-semibold">
      {/* Botón Cancelar */}
      <button
        type="button"
        onClick={handle_back}
        className={cn(
          "w-full lg:w-48 p-2 text-sm border hover:opacity-80 rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
          `border_${_color}`,
          `text_${_color}`
        )}
      >
        {cancelText}
      </button>

      {/* Botón Guardar */}
      <button
        disabled={disabled || isLoading}
        type="button"
        onClick={handle_save}
        className={cn(
          "w-full lg:w-48 p-2 text-sm text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
          {
            [`bg_${_color}`]: !disabled && !isLoading,
            "bg-slate-500": disabled || isLoading,
            "hover:opacity-80": !disabled && !isLoading,
          }
        )}
        aria-disabled={disabled || isLoading}
      >
        {isLoading ? "Guardando..." : saveText}
      </button>
    </div>
  );
};

export default ButtonGroup;
