import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";

export interface Column<T> {
  key: keyof T | string;
  header: string;
  className?: string;
  render?: (row: T) => React.ReactNode;
}

interface DataTableProps<T> {
  columns: Column<T>[];
  data: T[];
  className?: string;
}

export const DataTable = <T extends Record<string, any>>({
  columns,
  data,
  className,
}: DataTableProps<T>) => {
  return (
    // Contenedor con scroll horizontal si hay muchas columnas
    <div className={`overflow-x-auto w-full ${className}`}>
      {/* table-auto para que cada columna crezca según su contenido */}
      <Table className="table-auto border-collapse whitespace-nowrap">
        <TableHeader>
          <TableRow>
            {columns.map((col) => (
              <TableHead
                key={String(col.key)}
                className={`p-2 border bg-white text-left align-middle ${
                  col.className || ""
                }`}
              >
                {col.header}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((col, colIndex) => {
                const value = col.render ? col.render(row) : row[col.key];
                return (
                  <TableCell
                    key={`${rowIndex}-${String(col.key)}`}
                    className={`p-2 border align-middle bg-white ${
                      col.className || ""
                    }`}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataTable;