import { Breadcrumb, TitleScreen, FormSection, TextField, Switch } from "components/Core"
// import useSourcesResources from "hooks/entities/useSourcesResources"
import { useContext, useEffect, useState } from "react"
import AuthContext from "store/context/PublicContext"
import Swal from "sweetalert2"
import usePreRender from "hooks/usePreRender"
import cn from "classnames"
// import LabelSwitch from "components/Custom/LabelSwitch"
import useCombinedSourcesBFF from "hooks/bff_entities/useCombinedSourcesBFF"
import useLogisticSourcesResources from "hooks/entities/useLogisticSourcesResources"

const Chip: React.FC<any> = ({ enabled, label, onChange }) => {
  return (
    <div className="w-auto flex flex-row items-center justify-center">
      <Switch label={label} onChange={onChange} checked={enabled} className="text-sm" />
    </div>
  )
}

const ManageDeliveriesScreen = () => {
  const { _color } = usePreRender()

  const { user } = useContext(AuthContext)
  const [allStores, setAllStores] = useState<any>({
    id: "ALLSOURCES",
    name: "Todas las tiendas/bodegas",
    checked: true,
  })
  const [sourceTyped, setSourceTyped] = useState<string>("")
  const [stores, setStores] = useState<any>([])
  const [modifiedStores, setModifiedStores] = useState([])
  const user_has_all_sources = Boolean(user?.currentSources?.some((src: string) => src === "ALL"))
  // const { put_upsert_source_action } = useSourcesResources()
  const { put_update_schedule_source_action } = useLogisticSourcesResources()
  const { set_loading_app, post_list_source_combined_action } = useCombinedSourcesBFF()

  useEffect(() => {
    set_loading_app(true)
    const request = async () => {
      let filters: any = {}
      if (Boolean(!user_has_all_sources)) {
        Object.assign(filters, { id: user?.currentSources })
      }
      const res: any = await post_list_source_combined_action({
        body: {
          skipRecords: 0,
          maxRecords: 1000,
        },
      })
      setStores(
        (res?.data?.message?.records || []).map((source: any) => ({
          id: source.id,
          name: source.name,
          enabled: source.enabled,
          schedule: source.schedule,
        })),
      )
      set_loading_app(false)
    }
    request()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(stores.length) && Boolean(stores?.some((src: any) => !Boolean(src.enabled)))) {
      setAllStores({ ...allStores, enabled: false })
    }
    if (Boolean(stores.every((src: any) => Boolean(src.enabled)))) {
      setAllStores({ ...allStores, enabled: true })
    }
    //eslint-disable-next-line
  }, [stores])

  // Helper para manejar las modificaciones
  const updateStoreState = (updatedStore: any) => {
    setModifiedStores((prev: any) =>
      prev.some((store: any) => store.id === updatedStore.id)
        ? prev.map((store: any) => (store.id === updatedStore.id ? updatedStore : store))
        : [...prev, updatedStore],
    )
  }

  // Helper para cambiar el campo "enabled" de la tienda
  // const toggleStoreEnabled = (id: string) => {
  //   setStores((prev: any) =>
  //     prev.map((store: any) => {
  //       if (store.id === id) {
  //         const updatedStore = { ...store, enabled: !store.enabled }
  //         updateStoreState(updatedStore)
  //         return updatedStore
  //       }
  //       return store
  //     }),
  //   )
  // }

  // Helper para cambiar el campo "disabled" de un deliveryType
  const toggleDeliveryDisabled = (storeId: string, deliveryType: string) => {
    setStores((prev: any) =>
      prev.map((store: any) => {
        if (store.id === storeId) {
          const updatedSchedule = store.schedule.map((item: any) => {
            if (item.deliveryType === deliveryType) {
              const updatedItem = {
                ...item,
                disabled: !item.disabled,
              }
              return updatedItem
            }
            return item
          })

          const updatedStore = { ...store, schedule: updatedSchedule }
          updateStoreState(updatedStore)
          return updatedStore
        }
        return store
      }),
    )
  }

  const on_submit = async () => {
    try {
      set_loading_app(true)
      let _data: any = modifiedStores.map((src: any) => ({
        id: src?.id,
        schedule: src?.schedule.map((sch: any) => ({
          deliveryType: sch?.deliveryType,
          disabled: Boolean(sch?.disabled),
          shippingType: sch?.shippingType || [],
        })),
      }))
      // if (Boolean(allStores?.checked)) {
      //   _data = stores.map((src: any) => ({ id: src?.id, name: src?.name, enabled: true }))
      // } else {
      //   _data = stores.map((src: any) => ({ id: src?.id, name: src?.name, enabled: src?.checked }))
      // }
      // let res: any = await put_upsert_source_action({ body: _data })
      let res: any = await put_update_schedule_source_action({ body: _data })
      if (res?.data?.code < 400) {
        Swal.fire({
          title: "Hecho",
          text: "Tiendas/bodegas actualizadas",
          icon: "success",
        })
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "success",
      })
      set_loading_app(false)
    }
  }

  const filterSources = (query: string) => {
    const regex = new RegExp(query, "i") // Create regex with case-insensitive flag
    return stores.filter((src: any) => regex.test(src.name) || regex.test(src.id))
  }

  const sources_to_show: any = sourceTyped ? filterSources(sourceTyped) : stores

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Configuraciones", href: "/settings" },
          { name: "Control métodos de envío", href: "/settings/sources/deliveries" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Control métodos de envío" />
      </div>
      <div className="w-full flex flex-col py-2 px-4 lg:px-10">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <button
            onClick={on_submit}
            className={cn(
              "w-full lg:w-48 p-2 text-sm text-md hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
              `bg_${_color}`,
            )}
          >
            Guardar
          </button>
        </div>
        <div className="w-full flex flex-col items-center gap-4">
          <FormSection
            title="Tiendas/bodegas"
            helperText="Tiendas/bodegas"
            number={1}
            closed={false}
            className="lg:w-4/5"
          >
            <div className="w-full flex flex-col gap-4 lg:px-10 lg:py-10">
              <TextField
                label="Tienda/bodega"
                onChange={(e: any) => setSourceTyped(e.target.value)}
                value={sourceTyped}
              />
              <div className="w-full flex flex-col items-start justify-start">
                <div className="w-full max-h-[500px] overflow-auto pr-2">
                  {Boolean(sources_to_show?.length) ? (
                    sources_to_show.map((source: any, i: number) => (
                      <div key={i} className="w-full flex flex-col gap-4 p-4 border-b border-gray-200 hover:bg-gray-50">
                        <div className="w-full flex flex-row items-center justify-between gap-4">
                          <h4 className="font-semibold">{`${source.name} (${source.id})`}</h4>
                          <p
                            className={cn("text-sm italic", {
                              "text-green-500": source.enabled,
                              "text-red-500": !source.enabled,
                            })}
                          >
                            Tienda {source.enabled ? "Activa" : "Inactiva"}
                          </p>
                          {/* <LabelSwitch
                            id={`source--${source.id}`}
                            value={source.id}
                            onChange={() => toggleStoreEnabled(source.id)}
                            checked={source.enabled}
                            label={`${source.name} (${source.id})`}
                          /> */}
                        </div>
                        <div className="w-full flex flex-row items-start justify-start flex-wrap gap-6">
                          {source?.schedule?.map((_sch: any, _ii: number) => {
                            return (
                              <Chip
                                key={_ii}
                                id={`source_sch--${source.id}--${_sch.deliveryType}`}
                                value={source.id}
                                onChange={() => toggleDeliveryDisabled(source.id, _sch.deliveryType)}
                                enabled={!_sch?.disabled}
                                label={`${_sch?.deliveryType}`}
                              />
                            )
                          })}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>
                      {sourceTyped
                        ? `No se encontró la tienda/bodega '${sourceTyped}'`
                        : "No hay tiendas/bodegas asignadas"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </FormSection>
        </div>
      </div>
    </div>
  )
}

export default ManageDeliveriesScreen
