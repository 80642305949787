import { useContext } from "react"
import AuthContext from "store/context/PublicContext"
import { AppContext } from "store/context/AppContext"
import useCancelToken from "hooks/useCancelToken"
import OMNIX_MODULE from "constants/OMNIX_MODULE"
import {
  get_module_entity_action_middleware,
  post_module_entity_action_middleware,
  put_module_entity_action_middleware,
  delete_module_entity_action_middleware,
} from "services/middleware_services"
import Swal from "sweetalert2"

interface IGetSourceParams {
  source_id: string
  params?: any
}

interface IPostSourceParams {
  body: any
}

const useLogisticSourcesResources = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { loading_app, set_loading_app } = useContext(AppContext)

  const get_source_action = async ({ source_id, params = {} }: IGetSourceParams) => {
    try {
      return await get_module_entity_action_middleware(
        OMNIX_MODULE.OLM,
        `sources/${source_id}`,
        params,
        token,
        newCancelToken(),
      )
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const post_list_source_action = async ({ body }: IPostSourceParams) => {
    try {
      return await post_module_entity_action_middleware(OMNIX_MODULE.OLM, `sources/all`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const put_upsert_source_action = async ({ body }: IPostSourceParams) => {
    try {
      return await put_module_entity_action_middleware(OMNIX_MODULE.OLM, `sources`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const put_update_schedule_source_action = async ({ body }: IPostSourceParams) => {
    try {
      return await put_module_entity_action_middleware(OMNIX_MODULE.OLM, `sources/schedule-settings`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const delete_source_action = async ({ body }: IPostSourceParams) => {
    try {
      return await delete_module_entity_action_middleware(OMNIX_MODULE.OLM, `sources`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  return {
    get_source_action,
    post_list_source_action,
    put_upsert_source_action,
    put_update_schedule_source_action,
    delete_source_action,
    loading_app,
    set_loading_app,
  }
}

export default useLogisticSourcesResources
