import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
} from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { StateData } from "../../lib/types";

interface ChartsSectionProps {
  stateDistribution: StateData[];
  distributionData: Array<{
    name: string;
    value: number;
    percentage: number;
  }>;
}

const CHART_COLORS = {
  "On Track": "#22c55e",
  "Warning": "#eab308",
  "Críticas": "#ef4444",
};

export const ChartsSection = React.memo<ChartsSectionProps>(
  ({ stateDistribution, distributionData }) => {
    return (
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="col-span-2 bg-white">
          <Card>
            <CardHeader>
              <CardTitle>Distribución de Estados por Clasificación</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="w-full h-[500px] overflow-hidden">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={stateDistribution?.map((dist) => ({
                      state: dist.state,
                      "On Track": dist.onTrack,
                      Warning: dist.warningCount,
                      Críticas: dist.criticalCount,
                    }))}
                    margin={{ top: 20, right: 30, left: 40, bottom: 80 }}
                    barSize={35}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                      dataKey="state"
                      angle={-45}
                      textAnchor="end"
                      height={100}
                      interval={0}
                      tickSize={8}
                    />
                    <YAxis />
                    <RechartsTooltip
                      formatter={(value: any, name: string) => [
                        `${value}`,
                        `${name}`,
                      ]}
                      labelFormatter={(label: string) => `Estado: ${label}`}
                    />
                    <Legend verticalAlign="top" align="center" iconType="circle" />
                    <Bar
                      dataKey="On Track"
                      stackId="a"
                      fill={CHART_COLORS["On Track"]}
                      name="On Track"
                    />
                    <Bar
                      dataKey="Warning"
                      stackId="a"
                      fill={CHART_COLORS["Warning"]}
                      name="Warning"
                    />
                    <Bar
                      dataKey="Críticas"
                      stackId="a"
                      fill={CHART_COLORS["Críticas"]}
                      name="Críticas"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Distribución de Estados</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[500px] w-full">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={distributionData?.map((item) => ({
                      name:
                        item.name === "on_track"
                          ? "On Track"
                          : item.name === "warning"
                            ? "Warning"
                            : "Críticas",
                      value: item.value,
                      percentage: item.percentage,
                      color:
                        CHART_COLORS[
                          item.name === "on_track"
                            ? "On Track"
                            : item.name === "Warning"
                              ? "Warning"
                              : "Críticas"
                        ],
                    }))}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius="60%"
                    label={({ percentage }) => `${percentage}%`}
                  >
                    {distributionData?.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          CHART_COLORS[
                            distributionData[index].name === "on_track"
                              ? "On Track"
                              : distributionData[index].name === "warning"
                                ? "Warning"
                                : "Críticas"
                          ]
                        }
                      />
                    ))}
                  </Pie>
                  <RechartsTooltip
                    formatter={(value: number, name: string, props: any) => [
                      `${props.payload.percentage}% (${value})`,
                      name,
                    ]}
                  />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
);

ChartsSection.displayName = "ChartsSection";

export default ChartsSection;
