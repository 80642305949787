import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import { FormRoute } from "components/FormEntities"
import useForm from "hooks/useForm"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import route_values from "utils/forms_default/route_values"
import schemaValidationRoutes from "utils/yup/routes_schema"
import { parsed_body_to_send, fill_form_from_data } from "components/FormEntities/FormRoute/route.config"
import useRoutesResources from "hooks/entities/useRoutesResources"
import Swal from "sweetalert2"

const EditScreen = () => {
  const navigate = useNavigate()
  const { control, reset, watch, setValue, handleSubmit, errors } = useForm({
    defaultValues: route_values,
    schema: schemaValidationRoutes,
  })
  const form_route_ref = useRef<any>()
  const [route, set_route] = useState<any>()
  const { route_id } = useParams()
  const { data: data_routes, search_osrm_action: search_osrm_action_routes } = useSearchMiddlewareOSRM({
    entity: "routes",
  })
  const { set_loading_app, put_upsert_route_action } = useRoutesResources()
  const [canUpdate, setCanUpdate] = useState(false)
  const values = watch()

  useEffect(() => {
    search_osrm_action_routes({ from: 0, size: 1000, filters: { id: route_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_routes?.records.length)) {
      const get_route = async () => {
        let route_from_req = data_routes?.records[0]
        set_route(route_from_req)
      }
      get_route()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_routes])

  useEffect(() => {
    if (Object.values(values?.source?.data).length && Object.values(values?.target?.data).length) {
      setCanUpdate(true)
    } else {
      setCanUpdate(false)
    }
  }, [values])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    if (route) {
      reset(fill_form_from_data(route))
      route?.source?.type === "Source" && form_route_ref?.current?.handleEditSourceOnLoad(true)
      route?.target?.type === "Source" && form_route_ref?.current?.handleEditTargetOnLoad(true)
    }
    // eslint-disable-next-line
  }, [route])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      let res: any = await put_upsert_route_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Ruta creada",
          icon: "success",
        })
        navigate(`/routes/${body?.id}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  if (!route) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de rutas", href: "/routes" },
          { name: "Edición de ruta", href: `/routes/${route_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de ruta" string_id={route_id} />
      </div>
      <form onSubmit={handleSubmit(on_submit)} className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup disabled={!canUpdate} />
        </div>
        <FormRoute
          ref={form_route_ref}
          control={control}
          route={route}
          watch={watch}
          setValue={setValue}
          errors={errors}
          edit
        />
      </form>
    </div>
  )
}

export default EditScreen
