import { Breadcrumb, TitleScreen, Table, Button, Paginator, Select, Chip, Tooltip } from "components/Core"
import useSearchOSRM from "hooks/useSearchMiddlewareOSRM"
import { get_data_table_reports, parsed_filters_on_reports } from "helpers/data_tables"
import usePaginationTable from "hooks/usePaginationTable"
import { RxReload } from "react-icons/rx"
import { TiUpload } from "react-icons/ti"
import StatusLoadIndicator from "components/StatusLoadIndicator"
import { useCallback, useContext, useEffect, useState } from "react"
import Swal from "sweetalert2"
import { FaCircleCheck } from "react-icons/fa6"
import { PiWarningCircleFill } from "react-icons/pi"
import { BsFillQuestionCircleFill } from "react-icons/bs"
import { AppContext } from "store/context/AppContext"
import { MdFilterList } from "react-icons/md"
import { format_date } from "helpers/datetime_helper"
import { getFinalValueInDotNotation } from "helpers/table_filters_helper"

const MainScreen = () => {
  const { filters_table, open_table_filters, set_open_table_filters } = useContext(AppContext)
  const [selected_template, set_selected_template] = useState<string>()
  const [templates, set_templates] = useState<any[]>([])
  const [data, set_data] = useState<any[]>([])
  const { loading_app, set_loading_app, action_get_reports_osrm_action, action_post_reports_osrm_action } =
    useSearchOSRM({
      entity: "reports",
    })
  const { current_data, current_page, handle_page_change, handle_per_page, per_page, total_records, total_pages } =
    usePaginationTable({
      data,
    })

  const handle_filters = useCallback(
    () => set_open_table_filters(!open_table_filters),
    [open_table_filters, set_open_table_filters],
  )

  const get_reports = async () => {
    set_loading_app(true)
    let res: any = await action_get_reports_osrm_action(`reports?from=${per_page * current_page}&size=${per_page}`, {})
    let result = res?.data?.message
    set_data(result)
    set_loading_app(false)
    check_if_any_report_is_processing(result?.records) && setTimeout(() => get_reports(), 15000)
  }

  useEffect(() => {
    get_reports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page, per_page])

  useEffect(() => {
    const get_templates = async () => {
      set_loading_app(true)
      let res: any = await action_get_reports_osrm_action("templates?from=0&size=100", {})
      let result = res?.data?.message
      set_templates(
        result?.records?.map((record: any) => ({ id: record?.id, label: record?.name, value: record?.id, ...record })),
      )
      set_loading_app(false)
    }
    get_templates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [columns, data_table_reports] = get_data_table_reports(current_data)

  const get_state_label = (state: string) => {
    switch (state?.toLocaleUpperCase()) {
      case "FAILED":
        return (
          <span className="flex gap-2">
            <PiWarningCircleFill className="text-xl text-red-600" />
            <p>Error</p>
          </span>
        )
      case "UPLOADED":
      case "PROCESSED":
        return (
          <span className="flex gap-2">
            <FaCircleCheck className="text-green-600" />
            <p>Reporte generado</p>
          </span>
        )
      case "PROCESSING":
        return (
          <span className="flex gap-2">
            <BsFillQuestionCircleFill className="text-yellow-400" />
            <p>Procesando reporte</p>
          </span>
        )
      default:
        return state
    }
  }

  const check_if_any_report_is_processing = (_reports: any) => {
    return Boolean(_reports?.some((report: any) => report?.status?.toLocaleUpperCase() === "PROCESSING"))
  }

  const delete_active_filter = (key: string) => {
    filters_table.dispatch({ type: "DELETE_FILTER", payload: key })
  }

  const render_value = (field: any) => {
    const type = field?.type
    let value = field?.value
    if (field?.isDotNotation) {
      value = getFinalValueInDotNotation(value)
    }

    switch (type) {
      case "date":
        return Object.values(value)
          .map((date: any) => format_date(date))
          .join(", ")
      case "bool":
        return Boolean(value) ? "Habilitado" : "Deshabilitado"
      default:
        return JSON.stringify(value)
    }
  }

  const on_submit = async () => {
    try {
      set_loading_app(true)
      const res = await action_post_reports_osrm_action(`reports`, {
        templateId: selected_template,
        ...parsed_filters_on_reports(filters_table.state),
      })
      if (Number(res?.data?.code) < 400) {
        Swal.fire({
          title: "Hecho",
          text: "Generando reporte",
          icon: "success",
          showCloseButton: true,
        })
        handle_page_change(0)
        get_reports()
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }

  const translateError = (error: string) => {
    if (error.includes("EntityNotFoundException"))
      return "Error: No hay registros para el rango de fechas seleccionado."
    return error
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-start">
      <Breadcrumb data={[{ name: "Reportes", href: "/reports" }]} />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="Reportes" />
      </div>
      <div className="w-full flex flex-col gap-8">
        <div className="w-full flex flex-col lg:flex-row justify-between items-end lg:items-center gap-4 px-6">
          <div className="w-full md:w-auto xl:w-1/2 flex flex-col md:flex-row items-end justify-between gap-2 lg:gap-8 bg-white rounded-lg shadow-md p-4">
            <div className="w-full flex flex-col gap-2">
              <h4 className="whitespace-nowrap text-[#4C4C4C] font-medium">Tipo de reporte</h4>
              <Select
                label="Tipo"
                options={templates}
                onChange={(e) => set_selected_template(e)}
                value={selected_template}
              />
            </div>
            <div className="w-auto flex flex-col lg:flex-row justify-between items-end gap-6">
              <button
                onClick={handle_filters}
                className="relative flex flex-row items-center justify-center p-2 gap-1 font-semibold text-[#4C4C4C] border border-transparent transform transition duration-500 hover:scale-105"
              >
                {Object.keys(filters_table.state).length ? <span className="notification-dot"></span> : null}
                <MdFilterList className="text-2xl" />
                <p>Filtrar</p>
              </button>
              <Button
                label="Generar reporte"
                className="!whitespace-nowrap"
                icon={TiUpload}
                onClick={on_submit}
                disabled={Boolean(!selected_template)}
                // disabled
              />
            </div>
          </div>
          {Boolean(filters_table.state.length) ? (
            <div className="w-auto h-full flex flex-col items-start justify-start bg-white gap-2 rounded-lg shadow-md p-4">
              <h4 className="whitespace-nowrap text-[#4C4C4C] font-medium">Filtros actuales:</h4>
              <div className="w-full flex flex-row justify-end items-end">
                <div className="w-full max-w-md flex flex-row flex-wrap gap-2 py-2">
                  {filters_table?.state?.map((field: any, _i: number) => {
                    return (
                      <Chip
                        key={_i}
                        label={`${field?.label}: ${render_value(field)}`}
                        onDelete={() => delete_active_filter(field?.id)}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-full flex flex-col gap-2 px-6">
          <div className="w-full flex flex-row justify-between items-center px-4 py-2">
            <h4 className="text-lg text-[#4C4C4C] font-medium">Historial de reportes</h4>
            <StatusLoadIndicator on_success="Reporte generado" on_error="Error" on_load="Procesando reporte" />
          </div>
          <div className="w-full flex flex-col md:flex-row items-center justify-between gap-4">
            <Paginator
              current_page={current_page}
              handle_page_change={handle_page_change}
              per_page={per_page}
              total_records={total_records}
              total_pages={total_pages}
              handle_per_page={handle_per_page}
            />
            <div
              className="flex flex-row gap-1 justify-center items-center cursor-pointer text-sm text-[#4C4C4C] hover:shadow-md hover:bg-[#F2F2F2] p-2 rounded-lg"
              onClick={get_reports}
            >
              <RxReload />
              <p>Recargar</p>
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <Table>
              <Table.Head>
                {columns?.map((key: string) => (
                  <Table.HeadCell key={key}>{key}</Table.HeadCell>
                ))}
              </Table.Head>
              <Table.Body>
                {!loading_app && data_table_reports?.length ? (
                  data_table_reports.map((_report: any, i: number) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell mainCol>
                          <p className="italic">{_report?.id}</p>
                        </Table.Cell>
                        <Table.Cell>{_report?.type}</Table.Cell>
                        <Table.Cell>{_report?.date}</Table.Cell>
                        <Table.Cell>
                          {_report?.error ? (
                            <Tooltip content={translateError(_report?.error)} align="top">
                              <span className="cursor-help">{get_state_label(_report?.status)}</span>
                            </Tooltip>
                          ) : (
                            <span className="cursor-default">{get_state_label(_report?.status)}</span>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {Boolean(_report?.url && _report?.status?.toLocaleUpperCase() !== "FAILED") ? (
                            <a
                              href={_report?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-[#FD0049] underline"
                            >
                              Ver
                            </a>
                          ) : _report?.status?.toLocaleUpperCase() === "PROCESSING" ? (
                            <p>Generando reporte</p>
                          ) : (
                            <p>No disponible</p>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell>{loading_app ? "Espere..." : "Sin resultados"}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainScreen
