import React from 'react';
import { FaRegCopy  } from "react-icons/fa6"
import Swal from 'sweetalert2';

interface SourceCopyButtonProps {
  sourceId: string;
  sourceName: string;
  targetSourceId: string;
  targetSourceName: string;
  onCopy: (fromSourceId: string, toSourceId: string) => void;
}

export const SourceCopyButton: React.FC<SourceCopyButtonProps> = ({
  sourceId,
  sourceName,
  targetSourceId,
  targetSourceName,
  onCopy,
}) => {
  const handleCopyClick = async () => {
    const result = await Swal.fire({
      title: 'Copiar horario  de tiendas',
      text: `¿Está seguro de que desea copiar el horario de "${sourceName}" to "${targetSourceName}"? Esto sobreescribira el horario existente`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, Duplicalo',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#4F46E5',
      cancelButtonColor: '#6B7280'
    });

    if (result.isConfirmed) {
      onCopy(sourceId, targetSourceId);
      Swal.fire({
        title: 'Horario Copiado',
        text: `El horario se ha copiado correctamente a "${targetSourceName}"`,
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    }
  };

  return (
    <button
      onClick={handleCopyClick}
      className="p-1.5 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-50"
      title={`Copiar horario a ${targetSourceName}`}
    >
      <FaRegCopy size={16} />
    </button>
  );
};