import React from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { FaPlus } from "react-icons/fa";
import { TimeSlotRow } from './TimeSlotRow';
import { SlotsData  } from 'interfaces/ISlots';
import { cn } from 'utils/utils';
import usePreRender from 'hooks/usePreRender';

interface DayScheduleProps {
  dayId: string;
  form: UseFormReturn<SlotsData>;
  deliveryMethod?: string;
}

export const DaySchedule: React.FC<DayScheduleProps> = ({ 
  dayId, 
  form
}) => {
  const { _color } = usePreRender()
  const { fields, append, remove } = useFieldArray<any>({
    control: form.control,
    name: `${dayId}`,
  });

  const addTimeSlot = () => {
    append({ 
      from: "09:00",
      to: "17:00",
      capacity: 0,
      status: true,
      label: "Horario", 
    });
  };

  const showButtonAddByMethod = () => {
    return true;
  };

  return (
    <div>
      <h2 className="px-4 py-3 font-medium text-gray-700 bg-gray-50 border-b border-gray-200 flex flex-row inline-flex items-center gap-2">
        Tabla de configuración
        {
              showButtonAddByMethod() && (
              <button
                type="button"
                onClick={addTimeSlot}
                className= {cn(
                 "inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg transition-colors max-h-[25px]",
                  `text_${_color}`,
                )}
              >
                <FaPlus size={16} />
                Añadir
              </button>)
            }
      </h2>
      <div>
        <div className="bg-gray-50 p-4 border-b border-gray-200">
          <div className="grid grid-cols-[150px_150px_150px_150px_100px] gap-3">
            <div className="text-xs font-medium text-gray-500 flex flex-col justify-center items-center">TIEMPO DE INICIO</div>
            <div className="text-xs font-medium text-gray-500 flex flex-col justify-center items-center">TIEMPO DE FIN</div>
            <div className="text-xs font-medium text-gray-500 flex flex-col justify-center items-center">CAPACIDAD</div>
            <div className="text-xs font-medium text-gray-500 flex flex-col justify-center items-center">NOMBRE</div>
            <div className="text-xs font-medium text-gray-500 flex flex-col justify-center items-center">ESTADO</div>
          </div>
        </div>

        <div className="p-4 space-y-4">
          {fields.map((field, index) => (
            <TimeSlotRow
              key={field.id}
              index={index}
              register={form.register}
              onDelete={() => remove(index)}
              slotId={`${dayId}.${index}`}
            />
          ))}

          {fields.length === 0 && (
            <div className="text-center py-6 px-3 text-gray-500 text-sm">
              Ningun horario ha sido añadido
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};