import { SHIPPING_TYPES } from "constants/FORM_CONSTANTS"
import { format_date, formatManualTime } from "helpers/datetime_helper"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"

const statusses: any = translatesObj

// OOM
export const get_data_table_orders = (data: any) => {
  if (!Boolean(data)) return []

  const columns = [
    "EOC",
    "Id Omnix",
    "Portabilidad",
    "Canal",
    "Fecha de creación",
    "Estado actual",
    "Tipo de envío",
    "Tipo de entrega",
    "Fecha de entrega",
  ]

  return [
    columns,
    data.map((order: any) => {
      return {
        salesChannelId: order?.salesChannelId,
        id: order?.id,
        orderType: order?.orderType === "Portabilidad" ? "Si" : "No",
        channel: order?.channel,
        creationDate: format_date(order?.creationDate),
        currentStatus:
          statusses[order?._omnixStateMachine?.currentState?.state?.name]?.title ||
          order?._omnixStateMachine?.currentState?.state?.name,
        shippingType: SHIPPING_TYPES.find((ship: any) => ship.value === order?.shippingType)?.label,
        deliveryType: order?.deliveryType,
        estimatedDeliveryDate: format_date(order?.estimatedDeliveryDate),
      }
    }),
  ]
}

export const get_data_table_sgs = (data: any) => {
  if (!Boolean(data)) return []

  const columns = [
    "EOC",
    "Id Omnix",
    "Portabilidad",
    "Canal",
    "Id de orden",
    "Fecha de creación",
    "Estado actual",
    "Tipo de envío",
    "Slot de entrega",
    "Tipo de entrega",
    "Tienda/bodega",
  ]

  return [
    columns,
    data.map((sg: any) => {
      const isExpress = Boolean(sg?.deliveryType === "EXPRESS")
      const _slotDelivery = {
        initial: isExpress ? sg?.logisticPlan?.baseDate : sg?.slotDelivery?.initialHour,
        final: isExpress ? sg?.logisticPlan?.delivery : sg?.slotDelivery?.finalHour,
      }

      return {
        salesChannelId: sg?.salesChannelId,
        id: sg?.id,
        orderType: sg?.orderType === "Portabilidad" ? "Si" : "No",
        channel: sg?.channel,
        orderId: sg?.orderId,
        orderCreationDate: format_date(sg?.orderCreationDate),
        currentStatus:
          statusses[sg?._omnixStateMachine?.currentState?.state?.name]?.title ||
          sg?._omnixStateMachine?.currentState?.state?.name,
        shippingType: SHIPPING_TYPES.find((ship: any) => ship.value === sg?.shippingType)?.label,
        slotDelivery: `${formatManualTime(_slotDelivery.initial)} - ${formatManualTime(_slotDelivery.final)}`,
        deliveryType: sg?.deliveryType,
        source: `${sg?.source?.name} (${sg?.source?.id})`,
      }
    }),
  ]
}

export const get_data_table_items = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((item: any) => ({
    sku: item?.sku,
    name: item?.name,
    storePickup: JSON.stringify(item?.storePickup),
  }))
}
