import * as XLSX from "xlsx";

export type BuildExportParamsFn = () => URLSearchParams;
export type FetchOrdersExportFn = (params: URLSearchParams) => Promise<{
  totalItems: number;
  orders: any[];
}>;

export interface ExportExcelCallbacks {
  setProgress?: (p: number) => void;
  onExportStart?: () => void;
  onExportFinish?: () => void;
  onNotifySuccess?: (message: string) => void;
  onNotifyError?: (message: string) => void;
}

/**
 * Función que mapea SOLO las columnas que nos interesan,
 * expande las órdenes según los items y las series de cada ítem.
 * Filtra datos sensibles.
 */
function transformAndExpandOrders(orders: any[]): any[] {
  const rows: any[] = [];

  for (const order of orders) {
    // Mapeo base (sin datos sensibles):
    const baseData = {
      sgId: order.id ?? "",
      orderId: order.orderId ?? "",
      salesChannelId: order.salesChannelId ?? "",
      channel: order.channel ?? "",
      orderCreationDate: order.orderCreationDate ?? "",
      deliveryType: order.shippingType ?? "",
      region: order.logisticPlan?.route?.[0]?.target?.data?.criteria1 ?? "",
      provincia: order.logisticPlan?.route?.[0]?.target?.data?.criteria2 ?? "",
      commune: order.logisticPlan?.route?.[0]?.target?.data?.criteria3 ?? "",
      orderType: order.logisticPlan?.type ?? "",
      pickingId: order.pickingId ?? "",
      estimatedDeliveryDate: order.estimatedDeliveryDate ?? "",
      currentState: order._omnixStateMachine?.currentState?.state?.id ?? "",
      currentStateDate: order._omnixStateMachine?.currentState?.date ?? "",
      currentStateReason:
        order._omnixStateMachine?.currentState?.state?.custom?.reason ?? "",
      currentStateDescription:
        order._omnixStateMachine?.currentState?.state?.custom?.description ?? "",
      currentStateExecuter:
        order._omnixStateMachine?.currentState?.state?.custom?.executer?.name ??
        "",
      // Courier
      courier: order.logisticPlan?.route?.[0]?.courier?.name ?? "",
    };

    // Obtenemos los items
    const items = order.logisticPlan?.items ?? [];

    // Si no hay items, creamos una línea "vacía"
    if (items.length === 0) {
      rows.push({
        ...baseData,
        sku: "",
        quantity: "",
        serie: "",
      });
      continue; // Pasamos a la siguiente orden
    }

    // Procesamos cada item
    for (const item of items) {
      const seriesArr = Array.isArray(item.series) ? item.series : [];

      // Si hay series, repetimos tantas filas como series haya
      if (seriesArr.length > 0) {
        for (const s of seriesArr) {
          rows.push({
            ...baseData,
            sku: item.sku ?? "",
            quantity: item.quantity ?? "",
            // Tomamos la propiedad `serie` del objeto:
            serie: s.serie ?? "",
          });
        }
      } else {
        // Si NO hay series, solo una fila
        rows.push({
          ...baseData,
          sku: item.sku ?? "",
          quantity: item.quantity ?? "",
          serie: "",
        });
      }
    }
  }

  return rows;
}

/**
 * Exporta a Excel con la lógica de mapear solo campos importantes,
 * duplicar filas según los items y las series de cada ítem,
 * y omitir información sensible.
 */
export async function exportExcel(
  buildParams: BuildExportParamsFn,
  fetchFn: FetchOrdersExportFn,
  callbacks: ExportExcelCallbacks = {}
) {
  const {
    setProgress,
    onExportStart,
    onExportFinish,
    onNotifySuccess,
    onNotifyError,
  } = callbacks;

  try {
    onExportStart?.();

    let currentFrom = 0;
    const batchSize = 1000;
    let keepFetching = true;
    let total = 0;
    const allRecords: any[] = [];

    const baseParams = buildParams();

    // 1) Paginamos
    while (keepFetching) {
      const params = new URLSearchParams(baseParams);
      params.set("from", String(currentFrom));
      params.set("size", String(batchSize));

      const result = await fetchFn(params);
      if (!result) {
        onNotifyError?.("No se recibió respuesta del servidor.");
        return;
      }

      const { totalItems, orders } = result;
      total = totalItems || 0;

      allRecords.push(...orders);
      currentFrom += orders.length;

      const percent = total > 0 ? Math.round((currentFrom / total) * 100) : 0;
      setProgress?.(percent);

      if (orders.length < batchSize || currentFrom >= total) {
        keepFetching = false;
      }
    }

    if (allRecords.length === 0) {
      onNotifyError?.("No hay registros para exportar.");
      return;
    }

    // 2) Transformar y expandir
    const transformedData = transformAndExpandOrders(allRecords);

    // 3) Crear Excel
    const ws = XLSX.utils.json_to_sheet(transformedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Hoja_Ordenes");

    // 4) Generar binario
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const buf = binaryStringToArrayBuffer(wbout);

    // 5) Descargar
    const blob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const fileName = `ordenes_export_${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`;

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    onNotifySuccess?.(
      `Se exportaron ${transformedData.length} filas (expandidas por ítem y serie) en el archivo ${fileName}.`
    );
  } catch (error) {
    onNotifyError?.("Ocurrió un error al exportar Excel.");
  } finally {
    onExportFinish?.();
  }
}

function binaryStringToArrayBuffer(binaryStr: string): ArrayBuffer {
  const buf = new ArrayBuffer(binaryStr.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < binaryStr.length; i++) {
    view[i] = binaryStr.charCodeAt(i) & 0xff;
  }
  return buf;
}