import { Breadcrumb, TitleScreen } from "components/Core"
import {  get_data_table_sources_olm } from "helpers/data_tables"
import usePaginationTable from "hooks/usePaginationTable"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect } from "react" 
import useFiltersTable from "hooks/useFiltersTable"
import { parsed_filters_on_search } from "helpers/data_tables"
import { SlotsEditor } from "./SlotsEditor"

const ListScreen = () => {
  const { data, search_osrm_action } = useSearchMiddlewareOSRM({ entity: "logistic_sources" })
  const {
    current_data,
    current_page,
    handle_per_page,
    per_page,
    total_records,
  } = usePaginationTable({
    data,
  })
  const { filters_table, apply_filter } = useFiltersTable({ table_name: "logistic_sources" })

  useEffect(() => {
    let filtersState:any = parsed_filters_on_search(filters_table.state);
    let filters = filtersState?.["filters"];    
    let queryString:string = ""; 
    if(filters?.["queryString"]?.length){
      // eslint-disable-next-line react-hooks/exhaustive-deps
      queryString = filters["queryString"];
    }
    search_osrm_action({ from: 0, size: 1000, queryString  })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page, per_page, filters_table.state])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columns, data_table_sources] = get_data_table_sources_olm(current_data)
  
  const loadMoreSources = () => {
    handle_per_page((per_page + 10) < total_records ? per_page + 10 : total_records);
  }

  const filterSources = (search: string) => {
    apply_filter({
      field: "filters",
      value: { 
        queryString: search?.trim(),
      }
    })
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-start">
      <Breadcrumb data={[{ name: "Horarios de tiendas", href: "/slots" }]} />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="Horarios de tiendas" />
        {/* <p>RoutesListScreen</p> */}
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="w-full flex flex-col gap-2 px-6">
          <SlotsEditor 
            sourceInitList={data_table_sources}
            onLoadMore={(loadMoreSources)}
            onFilter={(filterSources)}
          />
        </div>
      </div>
    </div>
  )
}

export default ListScreen
