import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { SlotsData, Source } from 'interfaces/ISlots';
import { createEmptySchedule } from  'utils/utils'

export function useSlotsManagement(form: UseFormReturn<SlotsData>) {
  const { fields: selectedStores } = useFieldArray({
    control: form.control,
    name: 'selectedStores',
  });

  const { fields: sources, append: appendSource, remove: removeSource } = useFieldArray({
    control: form.control,
    name: 'sources',
  });

  const handleStoreChange = (newStores: Source[]) => {
    // Update selectedStores
    form.setValue('selectedStores', newStores);

    // Get current source IDs
    const currentSourceIds = sources.map(s => s._id);
    
    // Add new sources with empty schedules
    newStores.forEach(store => {
      if (!currentSourceIds.includes(store._id)) {
        appendSource({
          _id: store._id,
          name: store.name,
          schedule: createEmptySchedule(),
        });
      }
    });

    // Remove unselected sources
    const newSourceIds = newStores.map(s => s._id);
    const sourcesToRemove: number[] = [];
    sources.forEach((source, index) => {
      if (!newSourceIds.includes(source._id)) {
        sourcesToRemove.push(index);
      }
    });

    // Remove sources in reverse order to maintain correct indices
    sourcesToRemove.reverse().forEach(index => {
      removeSource(index);
    });
  };

  const getSourceIndex = (sourceId: string) => {
    return selectedStores.findIndex(source => source._id === sourceId);
  };

  const getSourceDeliveryMethods = (sourceId: string) => {
    return selectedStores.find(source => source._id === sourceId)?.schedule || [];
  };

  return {
    selectedStores,
    sources,
    handleStoreChange,
    getSourceIndex,
    getSourceDeliveryMethods
  };
}