import { Breadcrumb, Paginator, Table, TitleScreen, Select, Button } from "components/Core"
import { get_data_table_massive_load } from "helpers/data_tables"
import usePaginationTable from "hooks/usePaginationTable"
import { Controller } from "react-hook-form"
import business_schema from "utils/forms_default/business_schema"
import useForm from "hooks/useForm"
import StatusLoadIndicator from "components/StatusLoadIndicator"
import { FileInput } from "flowbite-react"
import useMassiveLoadResources from "hooks/customs/useMassiveLoadResources"
import { useEffect, useState } from "react"
import { Dropdown } from "flowbite-react"
import cn from "classnames"
import { RxReload } from "react-icons/rx"

import { TiUpload } from "react-icons/ti"
import { FaCircleCheck } from "react-icons/fa6"
import { PiWarningCircleFill } from "react-icons/pi"
import { BsFillQuestionCircleFill } from "react-icons/bs"
import schemaValidationLoads from "utils/yup/loads_schema"
import usePreRender from "hooks/usePreRender"
import Swal from "sweetalert2"

const options = [
  { label: "Rutas", value: "routes" },
  { label: "Coberturas", value: "coverages" },
  { label: "Couriers", value: "couriers" },
]

const MainScreen = () => {
  const { _color } = usePreRender()
  const [data, set_data] = useState<any>()
  const { current_data, current_page, handle_page_change, handle_per_page, per_page, total_records, total_pages } =
    usePaginationTable({
      data,
    })
  const { post_list_massive_load_action, post_upload_file_massive_load_action, loading_app, set_loading_app } =
    useMassiveLoadResources()
  const { control, handleSubmit, register, errors } = useForm({
    defaultValues: business_schema,
    schema: schemaValidationLoads,
  })

  const req_list = async () => {
    set_loading_app(true)
    const res: any = await post_list_massive_load_action({
      body: {
        skipRecords: per_page * current_page,
        maxRecords: per_page,
      },
    })
    const result = res?.data?.message
    set_data({
      records: result?.records,
      pages: result?.maxPages,
      total: result?.totalRecordsMatched,
    })
    set_loading_app(false)
    check_if_any_load_is_processing(result?.records) && setTimeout(() => req_list(), 30000)
  }

  useEffect(() => {
    try {
      req_list()
    } catch (error) {
      set_loading_app(false)
    }
    // eslint-disable-next-line
  }, [current_page, per_page])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const res: any = await post_upload_file_massive_load_action({ body: { file: data?.file }, type: data?.type })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Carga completada",
          icon: "success",
          showCloseButton: true,
        })
        handle_page_change(0)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }

  const [columns, data_table_loads] = get_data_table_massive_load(current_data)

  const get_state_label = (state: string) => {
    switch (state) {
      case "FAILED":
        return (
          <span className="flex gap-2">
            <PiWarningCircleFill className="text-xl text-red-600" />
            <p>Error</p>
          </span>
        )
      case "UPLOADED":
        return (
          <span className="flex gap-2">
            <FaCircleCheck className="text-green-600" />
            <p>Carga exitosa</p>
          </span>
        )
      case "PROCESSING":
      case "PROCESSED":
      case "UPLOADING":
        return (
          <span className="flex gap-2">
            <BsFillQuestionCircleFill className="text-yellow-400" />
            <p>Procesando</p>
          </span>
        )
      default:
        return state
    }
  }

  const check_if_any_load_is_processing = (_loads: any) => {
    return Boolean(_loads?.some((load: any) => load?.currentStatus?.name?.toLocaleUpperCase() === "PROCESSING"))
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-start">
      <Breadcrumb data={[{ name: "Carga masiva", href: "/bulk" }]} />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="Carga masiva" />
      </div>
      <div className="w-full flex flex-col gap-4">
        <form
          onSubmit={handleSubmit(on_submit)}
          className="w-full flex flex-col lg:flex-row justify-between items-end lg:items-center gap-4 px-6"
        >
          <div className="w-full xl:w-3/4 flex flex-col md:flex-row items-end justify-between gap-4 lg:gap-8 bg-white rounded-lg shadow-md p-4">
            <div className="flex flex-row gap-2 items-end">
              <div className="flex flex-col gap-2">
                <h4 className="whitespace-nowrap text-[#4C4C4C] font-medium">Tipo de carga</h4>
                <Controller
                  name="type"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select label="Tipo" options={options} onChange={onChange} value={value} className="!w-60" />
                  )}
                />
              </div>
              <FileInput {...register("file")} />
            </div>
            <Button type="submit" label="Cargar archivo" className="!ml-10 !whitespace-nowrap" icon={TiUpload} />
          </div>
          <div className="w-auto h-full grid place-content-end bg-white rounded-lg shadow-md gap-2 p-4">
            {/* <p className="font-semibold text-[#FD0049] underline cursor-pointer">Descargar plantilla</p> */}
            <h4 className="whitespace-nowrap text-[#4C4C4C] font-medium">Plantillas</h4>
            <Dropdown label="Descargar plantilla" className={cn(`!bg_[${_color}] p-0 font-semibold`)}>
              <Dropdown.Item onClick={() => window.open("/assets/massive_loads/routes.xlsx", "_blank")}>
                Rutas
              </Dropdown.Item>
              <Dropdown.Item onClick={() => window.open("/assets/massive_loads/coverages.xlsx", "_blank")}>
                Coberturas
              </Dropdown.Item>
              <Dropdown.Item onClick={() => window.open("/assets/massive_loads/couriers.xlsx", "_blank")}>
                Couriers
              </Dropdown.Item>
            </Dropdown>
          </div>
        </form>
        {Object.keys(errors).length ? (
          <div className="w-full px-6">
            <ol className="w-1/2 flex flex-col bg-white rounded-lg shadow-md p-4">
              <li className="italic text-sm">Intente nuevamente:</li>
              {Object.values(errors).map((_err: any, i: number) => (
                <li className="text-[#FD0049] italic text-xs left-1" key={i}>
                  - {_err?.message}
                </li>
              ))}
            </ol>
          </div>
        ) : null}
        <div className="w-full flex flex-col gap-2 px-6">
          <div className="w-full flex flex-row justify-between items-center px-4 py-2">
            <h4 className="text-lg text-[#4C4C4C] font-medium">Historial de cargas masivas</h4>
            <StatusLoadIndicator on_success="Carga exitosa" on_error="Error" on_load="Procesando" />
          </div>
          <div className="w-full flex flex-col md:flex-row items-center justify-between gap-4">
            <Paginator
              current_page={current_page}
              handle_page_change={handle_page_change}
              per_page={per_page}
              total_records={total_records}
              total_pages={total_pages}
              handle_per_page={handle_per_page}
            />
            <div
              className="flex flex-row gap-1 justify-center items-center cursor-pointer text-sm text-[#4C4C4C] hover:shadow-md hover:bg-[#F2F2F2] p-2 rounded-lg"
              onClick={req_list}
            >
              <RxReload />
              <p>Recargar</p>
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <Table>
              <Table.Head>
                {columns?.map((key: string) => (
                  <Table.HeadCell key={key}>{key}</Table.HeadCell>
                ))}
              </Table.Head>
              <Table.Body>
                {!loading_app && data_table_loads?.length ? (
                  data_table_loads?.map((load: any, i: number) => (
                    <Table.Row key={i}>
                      {Object.values(load).map((value: any, i: number) =>
                        i === 0 ? (
                          <Table.Cell key={i} mainCol>
                            <p className="text-[#F9004D] underline">{value}</p>
                          </Table.Cell>
                        ) : load?.currentStatus && i === Object.values(load).indexOf(load?.currentStatus) ? (
                          <Table.Cell key={i}>{get_state_label(load?.currentStatus)}</Table.Cell>
                        ) : (
                          <Table.Cell key={i}>{value}</Table.Cell>
                        ),
                      )}
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>{loading_app ? "Espere..." : "Sin resultados"}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainScreen
