import { useEffect, useState } from "react"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"

interface ISelectServiceOptions {
  field: string
}

interface IOptionSelect {
  id: any
  label: string
  value: string
}

const statussesTranslates: any = translatesObj

const statusses = [
  "CONFIRMED",
  "ASSIGNED",
  "IN_PREPARATION",
  "PICKING_ID_GENERATED",
  "PICKING_IN_PROGRESS",
  "VALIDATE_PICKING",
  "PICKING_CONFIRMED",
  "PICKING_COMPLETED_PENDING_PACKAGE",
  "PACKED_AND_READY_FOR_PICKUP",
  "DELIVERED_TO_COURIER",
  "DELIVER_TO_COURIER",
  "IN_TRANSIT",
  "ORDER_RECEIVED",
  "DELIVERED",
  "DELIVERED_IN_STORE",
  "READY_FOR_PICKUP",
  "PICKUP_AVAILABLE",
  "SUCCESSFUL_PAYMENT",
  "PICKED_UP",
  "CANCELED",
  "DELIVERED_IN_SOURCE",
  "ORDER_RETURNED",
]

const useSelectServiceOptions = ({ field }: ISelectServiceOptions) => {
  const [options, set_options] = useState<Array<IOptionSelect>>([])
  const { data: data_delivery_types, search_osrm_action: search_osrm_action_delivery_types } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })
  const { data: data_channels, search_osrm_action: search_osrm_action_channels } = useSearchMiddlewareOSRM({
    entity: "channels",
  })
  const { data: data_couriers, search_osrm_action: search_osrm_action_couriers } = useSearchMiddlewareOSRM({
    entity: "couriers",
  })

  useEffect(() => {
    if (field === "currentState") {
      set_options(
        statusses.map((state: string, _i: number) => ({
          id: _i,
          label: statussesTranslates[state]?.title || state,
          value: state,
        })),
      )
    }
    if (field === "deliveryType") {
      search_osrm_action_delivery_types({ from: 0, size: 1000, fields: ["id", "deliveryType"] })
    }
    if (field === "channel") {
      search_osrm_action_channels({ from: 0, size: 1000, fields: ["id", "name"] })
    }
    if (field === "courier") {
      search_osrm_action_couriers({ from: 0, size: 1000, fields: ["id", "name"] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  useEffect(() => {
    if (data_delivery_types?.total) {
      set_options(
        data_delivery_types?.records?.map((delivery: any, _i: number) => ({
          id: _i,
          label: delivery?.deliveryType,
          value: delivery?.deliveryType,
        })),
      )
    }
    if (data_channels?.total) {
      set_options(
        data_channels?.records?.map((channel: any, _i: number) => ({
          id: _i,
          label: channel?.name,
          value: channel?.id,
        })),
      )
    }
    if (data_couriers?.total) {
      set_options(
        data_couriers?.records?.map((channel: any, _i: number) => ({
          id: _i,
          label: channel?.name,
          value: channel?.id,
        })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_delivery_types, data_channels, data_couriers])

  return {
    options,
  }
}

export default useSelectServiceOptions
