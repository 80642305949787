import React from 'react';
import { FaRegCopy  } from "react-icons/fa6"
import Swal from 'sweetalert2';
import { cn } from 'utils/utils';
import { DaysNumber } from './SourceSchedule';
import usePreRender from 'hooks/usePreRender';

interface VerticalTabsProps {
  days: Array<{ id: DaysNumber; label: string }>;
  activeTab: DaysNumber;
  onTabChange: (id: DaysNumber) => void;
  onCloneDay: (fromDay: DaysNumber) => void;
}

export const VerticalTabs: React.FC<VerticalTabsProps> = ({
  days,
  activeTab,
  onTabChange,
  onCloneDay,
}) => {
  const { _color } = usePreRender()
  const handleCloneClick = async (targetDayId: DaysNumber, targetDayLabel: string) => {
    const result = await Swal.fire({
      title: 'Copiar Horario',
      text: `¿Está seguro de que desea clonar la programación actual a ${targetDayLabel}? Esto sobreescribira el horario existente.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, Duplicalo',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#4F46E5',
      cancelButtonColor: '#6B7280'
    });

    if (result.isConfirmed) {
      onCloneDay(targetDayId);
      Swal.fire({
        title: 'Horario Clonado',
        text: `El horario ha sido clonado exitosamente a ${targetDayLabel}`,
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    }
  };

  return (
    <div className="min-w-[180px] border-r border-gray-200 ">
      <h2 className="px-1 py-3 font-medium text-gray-700 bg-gray-50 border-b border-gray-200">
        Días de la semana
      </h2>
      <div className="py-4">
        <div className="space-y-1 px-1">
          {days.map(({ id, label }) => (
            <div key={id} className="flex items-center gap-2">
              <button
                onClick={() => onTabChange(id)}
                className={cn(
                  "w-full text-left px-4 py-2.5 rounded-lg text-sm font-medium transition-colors",
                  activeTab === id
                    ? `border border_${_color} text_${_color} bg-gray-50`
                    : "text-gray-600 hover:bg-gray-50"
                )}
              >
                {label}
              </button>
              {activeTab !== id && (
                <button
                  onClick={() => handleCloneClick(id, label)}
                  className="p-1.5 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-50"
                  title={`Clone current day schedule to ${label}`}
                >
                  <FaRegCopy size={16} />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}