// hooks/useAuthToken.ts
import { useContext } from "react";
import AuthContext from "store/context/PublicContext";

const useAuthToken = (): string | undefined => {
  const {
    user: { token },
  } = useContext(AuthContext);

  return token;
};

export default useAuthToken;