import { useState, useRef } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import type { FilterOptionType } from "../lib/types";

interface MultiSelectFilterProps {
  id: string;
  title: string;
  placeholder: string | undefined;
  options: FilterOptionType[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
}

export function MultiSelectFilter({
  id,
  title,
  placeholder,
  options,
  selectedValues,
  onChange,
}: MultiSelectFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleValueChange = (value: string) => {
    if (value === "all") {
      onChange([]);
      return;
    }

    const newValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    onChange(newValues);
  };

  const getSelectedLabels = () => {
    return selectedValues
      ?.map((value) => options?.find((opt) => opt.value === value)?.label)
      ?.filter((label): label is string => label !== undefined);
  };

  return (
    <div className="min-w-[200px]" ref={selectRef}>
      <Label className="text-sm mb-2 block">{title}</Label>
      <div className="space-y-2">
        <Select
          value={selectedValues.length === 0 ? "all" : selectedValues[selectedValues.length - 1]}
          onValueChange={handleValueChange}
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder={placeholder}>
              {selectedValues.length === 0
                ? "Todos"
                : `${selectedValues.length} seleccionado(s)`}
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="bg-white shadow-md border border-gray-200">
            <SelectItem value="all">Todos</SelectItem>
            {options?.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {selectedValues.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {getSelectedLabels()?.map((label, index) => (
              <Button
                key={selectedValues[index]}
                variant="secondary"
                size="sm"
                className="h-7 rounded-full"
                onClick={() => handleValueChange(selectedValues[index])}
              >
                {label}
                <span className="ml-1">×</span>
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
