import { CLIENT, ENV } from "config/init";

interface IAuth0Client {
  [client: string]: {
    [env: string]: {
      clientId: string | undefined;
      audience: string | undefined;
      domain: string | undefined;
    };
  }
}

export const CLIENTS_AUTH0: IAuth0Client = {
  [CLIENT.ENTEL]: {
    [ENV.DEV]: {
      clientId: process.env.REACT_APP_AUTH0_ENTEL_CLIENT_ID_DEV,
      audience: process.env.REACT_APP_AUTH0_ENTEL_AUDIENCE_DEV,
      domain: process.env.REACT_APP_AUTH0_ENTEL_DOMAIN_DEV,
    },
    [ENV.UAT]: {
      clientId: process.env.REACT_APP_AUTH0_ENTEL_CLIENT_ID_UAT,
      audience: process.env.REACT_APP_AUTH0_ENTEL_AUDIENCE_UAT,
      domain: process.env.REACT_APP_AUTH0_ENTEL_DOMAIN_UAT,
    },
    [ENV.PROD]: {
      clientId: process.env.REACT_APP_AUTH0_ENTEL_CLIENT_ID_PROD,
      audience: process.env.REACT_APP_AUTH0_ENTEL_AUDIENCE_PROD,
      domain: process.env.REACT_APP_AUTH0_ENTEL_DOMAIN_PROD,
    },
  },
  [CLIENT.ENTEL_PERU]: {
    [ENV.DEV]: {
      clientId: process.env.REACT_APP_AUTH0_ENTELPERU_CLIENT_ID_DEV,
      audience: process.env.REACT_APP_AUTH0_ENTELPERU_AUDIENCE_DEV,
      domain: process.env.REACT_APP_AUTH0_ENTELPERU_DOMAIN_DEV,
    },
    [ENV.UAT]: {
      clientId: process.env.REACT_APP_AUTH0_ENTELPERU_CLIENT_ID_UAT,
      audience: process.env.REACT_APP_AUTH0_ENTELPERU_AUDIENCE_UAT,
      domain: process.env.REACT_APP_AUTH0_ENTELPERU_DOMAIN_UAT,
    },
    [ENV.PROD]: {
      clientId: process.env.REACT_APP_AUTH0_ENTELPERU_CLIENT_ID_PROD,
      audience: process.env.REACT_APP_AUTH0_ENTELPERU_AUDIENCE_PROD,
      domain: process.env.REACT_APP_AUTH0_ENTELPERU_DOMAIN_PROD,
    },
  },
}