import { FormSection, Select, TextField, SearchableSelect } from "components/Core"
import { useState } from "react"
import { Controller } from "react-hook-form"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"
import { SAFETY_TYPE } from "./stock.config"

interface IFormStock {
  control: any
  watch: any
  setValue: any
  edit?: boolean
  errors: any
}

const FormStock = ({ control, errors, edit }: IFormStock) => {
  const { action_search_osrm_action } = useSearchMiddlewareOSRM({})
  const [options, setOptions] = useState<any>([])
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  const getLocationsAction = async (queryString: string) => {
    let params = { skipRecords: 0, maxRecords: 100, queryString, fields: ["id", "name"] }
    try {
      setOptions([])
      setIsLoadingSearch(true)
      let res: any = await action_search_osrm_action("sources", params)
      if (Boolean(res?.data?.message?.total)) {
        let result = res?.data?.message?.records?.map((src: any) => ({ id: src?.id, name: src?.name }))
        setOptions(result)
        setIsLoadingSearch(false)
        return result
      } else {
        setIsLoadingSearch(false)
        return []
      }
    } catch (error) {
      setIsLoadingSearch(false)
      console.log("Error", JSON.stringify(error))
    }
  }

  const validationsSectionsErrors = {
    1: Boolean(["id", "name"].some((key: string) => Object.keys(errors).includes(key))),
    2: Boolean(["sources"].some((key: string) => Object.keys(errors).includes(key))),
    3: Boolean(["safety"].some((key: string) => Object.keys(errors).includes(key))),
  }

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection
        title="Información general"
        helperText="Información general"
        number={1}
        classNameContent="p-4"
        anyError={validationsSectionsErrors[1]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="id"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Id" onChange={onChange} value={value} disabled={Boolean(edit)} />
              )}
            />
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Nombre del stock" onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[1] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Sources"
        helperText="Sources"
        number={2}
        classNameContent="p-4"
        anyError={validationsSectionsErrors[2]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-8">
          <SearchableSelect
            name="sources"
            control={control}
            options={options}
            onSearch={getLocationsAction}
            placeholder="Sources"
            isLoading={isLoadingSearch}
            isMulti
            seeLabelWithId
          />
          {validationsSectionsErrors[2] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Stock de seguridad"
        helperText="Stock de seguridad"
        number={3}
        classNameContent="p-4"
        anyError={validationsSectionsErrors[3]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="safety.type"
              control={control}
              defaultValue="fixed"
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo" options={SAFETY_TYPE} onChange={onChange} value={value} disabled />
              )}
            />
            <Controller
              name="safety.value"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Valor" type="number" onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[3] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
    </div>
  )
}

export default FormStock
