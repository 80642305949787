import { Button, Collapse, TextField } from "components/Core"
import { useState } from "react"

interface IFieldString {
  field: any
  apply_filter: any
}

const FieldString = ({ field: _field, apply_filter }: IFieldString) => {
  const { id, label } = _field
  const [value, set_value] = useState("")

  const handle_change_value = (e: any) => {
    e.preventDefault()
    set_value(e?.target?.value)
  }

  const apply_filter_value = () => {
    apply_filter({ ..._field, value })
    set_value("")
  }

  return (
    <Collapse name={id} header={<h1>{label}</h1>}>
      <div className="w-full flex flex-row items-center justify-center gap-2">
        <TextField label={label} onChange={handle_change_value} value={value} />
        <Button label="Aplicar" disabled={Boolean(!value)} className="w-28 !p-0" onClick={apply_filter_value} />
      </div>
    </Collapse>
  )
}

export default FieldString
