import React from "react";
import { DataTable, type Column } from "./DataTable";
import type { Order } from "../../lib/types";

interface OrdersTableProps {
  orders: Order[];
}

export const OrdersTable: React.FC<OrdersTableProps> = ({ orders }) => {
  // Fijamos ID y Clasificación
  const pinnedColumns: Column<Order>[] = [
    {
      key: "id",
      header: "ID",
      // Primera columna fija con un ancho aproximado
      className: "sticky left-0 z-10 w-40",
    },
    {
      key: "classification",
      header: "Clasificación",
      className: "sticky left-40 z-10 w-40", 
      render: (row) => (
        <span
          className={`px-2 py-1 rounded-full text-xs font-medium ${
            row.classification === "on_track"
              ? "bg-green-100 text-green-800"
              : row.classification === "warning"
              ? "bg-yellow-100 text-yellow-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {row.classification === "on_track"
            ? "On Track"
            : row.classification === "warning"
            ? "Warning"
            : "Crítica"}
        </span>
      ),
    },
  ];

  // Recojo las keys fijas para no generarlas de nuevo dinámicamente
  const pinnedKeys = pinnedColumns.map((col) => col.key);

  function getDynamicColumns(data: Order[]): Column<Order>[] {
    if (!data?.length) return [];
    const dynamicKeys = new Set<string>();

    for (const row of data) {
      for (const key of Object.keys(row)) {
        if (!pinnedKeys.includes(key)) {
          const value = (row as any)[key];
          if (
            value !== null &&
            (typeof value === "string" ||
              typeof value === "number" ||
              typeof value === "boolean")
          ) {
            dynamicKeys.add(key);
          }
        }
      }
    }

    return Array.from(dynamicKeys).map((key) => ({
      key,
      header: key === "orderCreationDate" ? "Fecha" : key,
      render:
        key === "orderCreationDate"
          ? (row) =>
              new Date(row.orderCreationDate).toLocaleDateString("es-CL")
          : undefined,
    }));
  }

  const dynamicColumns = getDynamicColumns(orders);
  const columns = [...pinnedColumns, ...dynamicColumns];

  return <DataTable columns={columns} data={orders} />;
};

export default OrdersTable;