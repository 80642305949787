import React from "react";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";

interface SummaryData {
  totalOrders: number;
  onTrack: number;
  warning: number;
  critical: number;
}

interface SummaryCardsProps {
  summaryData: SummaryData;
}

export const SummaryCards = React.memo<SummaryCardsProps>(({ summaryData }) => {
  return (
    <div className="grid md:grid-cols-3 gap-6  bg-white">
      <Card>
        <CardHeader className="bg-green-50">
          <CardTitle className="text-green-700">On Track</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-4xl font-bold text-green-600">
            {summaryData.onTrack}
          </p>
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader className="bg-yellow-50">
          <CardTitle className="text-yellow-700">Warning</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-4xl font-bold text-yellow-600">
            {summaryData.warning}
          </p>
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader className="bg-red-50">
          <CardTitle className="text-red-700">Críticas</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-4xl font-bold text-red-600">
            {summaryData.critical}
          </p>
        </CardContent>
      </Card>
    </div>
  );
});

SummaryCards.displayName = "SummaryCards";

export default SummaryCards;
