import { lazy, Suspense } from "react";
import { Skeleton } from "../ui/skeleton";

const ResultsTableComponent = lazy(() => import("./ResultsTable"));
const OrdersTableComponent = lazy(() => import("./OrdersTable"));

export const ResultsTable = (props: any) => (
  <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
    <ResultsTableComponent {...props} />
  </Suspense>
);

export const OrdersTable = (props: any) => (
  <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
    <OrdersTableComponent {...props} />
  </Suspense>
);
