import { useContext } from "react"
import AuthContext from "store/context/PublicContext"
import { AppContext } from "store/context/AppContext"
import useCancelToken from "hooks/useCancelToken"
import OMNIX_MODULE from "constants/OMNIX_MODULE"
import { post_module_entity_action_middleware, put_module_entity_action_middleware } from "services/middleware_services"
import Swal from "sweetalert2"

interface IPostStockParams {
  id?: string
  body: any
}

const useStockResources = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { loading_app, set_loading_app } = useContext(AppContext)

  const post_create_stock_action = async ({ body }: IPostStockParams) => {
    try {
      return await post_module_entity_action_middleware(
        OMNIX_MODULE.OIM,
        `inventory/stock`,
        body,
        token,
        newCancelToken(),
      )
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const put_update_stock_action = async ({ body, id }: IPostStockParams) => {
    try {
      return await put_module_entity_action_middleware(
        OMNIX_MODULE.OIM,
        `inventory/stock/${id}`,
        body,
        token,
        newCancelToken(),
      )
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  return {
    post_create_stock_action,
    put_update_stock_action,
    loading_app,
    set_loading_app,
  }
}

export default useStockResources
