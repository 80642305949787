import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const exportDashboard = async () => {
  const dashboardSection = document.getElementById("dashboard-section");
  const resultsSection = document.getElementById("results-section");

  if (!dashboardSection || !resultsSection) {
    console.error("One or more sections are missing!");
    return;
  }

  const tempContainer = document.createElement("div");
  tempContainer.style.width = "fit-content";
  tempContainer.style.backgroundColor = "white";
  tempContainer.style.padding = "20px";
  tempContainer.style.fontFamily = "Arial, sans-serif";

  const dashboardClone = dashboardSection.cloneNode(true) as HTMLElement;
  const resultsClone = resultsSection.cloneNode(true) as HTMLElement;

  tempContainer.appendChild(dashboardClone);
  tempContainer.appendChild(document.createElement("hr"));
  tempContainer.appendChild(resultsClone);

  document.body.appendChild(tempContainer);

  try {
    const canvas = await html2canvas(tempContainer, {
      scale: 1,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/png");
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [contentWidth, contentHeight],
    });

    pdf.addImage(imgData, "PNG", 0, 0, contentWidth, contentHeight);
    pdf.save("order_alerts_dashboard.pdf");
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    document.body.removeChild(tempContainer);
  }
};
