import React from "react";
import { Button } from "../ui/button";
import { FiDownload } from "react-icons/fi";

interface DashboardHeaderProps {
  onExport: () => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ onExport }) => {
  return (
    <header className="flex justify-between items-center mb-8">
      <div>
        <h1 className="text-4xl font-bold text-foreground mb-2">
          Ciclo de vida de órdenes
        </h1>
        <p className="text-muted-foreground">
          Monitoreo y análisis: ciclo de vida de órdenes
        </p>
      </div>
      <div className="flex gap-4 bg-white">
        <Button onClick={onExport} variant="outline" className="gap-2">
          <FiDownload className="h-4 w-4" />
          Exportar Dashboard PDF
        </Button>
      </div>
    </header>
  );
};

export default DashboardHeader;
