import React from "react";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { Label } from "../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { ExportExcelButton } from "./ExportExcelButton";
import OrdersTable from "./OrdersTable";
import type { Order } from "../../lib/types";

interface OrdersListProps {
  orders: Order[];
  sampleSize: number;
  onSampleSizeChange: (size: number) => void;
  // Por si necesitas los filtros en este componente
  currentFilters?: Record<string, any>;
}

const OrdersList: React.FC<OrdersListProps> = ({
  orders,
  sampleSize,
  onSampleSizeChange,
  currentFilters = {},
}) => {
  const displayOrders = orders?.slice(-sampleSize) || [];

  // Construir los filtros en URLSearchParams
  const buildExportParams = () => {
    const params = new URLSearchParams();

    if (currentFilters.classification?.length) {
      params.set("classification", currentFilters.classification.join(","));
    }
    if (currentFilters.deliveryType?.length) {
      params.set("delivery_type", currentFilters.deliveryType.join(","));
    }
    if (currentFilters.shippingType?.length) {
      params.set("shipping_type", currentFilters.shippingType.join(","));
    }
    if (currentFilters.dateFrom) {
      params.set("startDate", currentFilters.dateFrom);
    }
    if (currentFilters.dateTo) {
      params.set("endDate", currentFilters.dateTo);
    }
    if (currentFilters.searchTerm?.trim()) {
      params.set("search", currentFilters.searchTerm.trim());
    }
  
    return params;
  };  

  return (
    <Card className="bg-white">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Lista de órdenes</CardTitle>
        <ExportExcelButton buildExportParams={buildExportParams} />
      </CardHeader>
      <CardContent>
        <div className="rounded-md border">
          <OrdersTable orders={displayOrders} />
        </div>
        <div className="mt-4 flex items-center">
          <div className="flex items-center gap-2">
            <Label>Mostrar últimos:</Label>
            <Select
              value={sampleSize.toString()}
              onValueChange={(value) => onSampleSizeChange(Number(value))}
            >
              <SelectTrigger className="w-[80px]">
                <SelectValue placeholder="5" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectItem value="5">5</SelectItem>
                <SelectItem value="10">10</SelectItem>
                <SelectItem value="20">20</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default OrdersList;