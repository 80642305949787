import { parsed_filters_on_search } from "helpers/data_tables"

interface IFilterData {
  isDotNotation?: boolean
  field: any
  value: any
}

export const combineValues = (jsonArray: Array<any>) => {
  const deepMerge = (target: any, source: any) => {
    for (const key in source) {
      if (source[key] && typeof source[key] === "object" && !Array.isArray(source[key])) {
        target[key] = deepMerge(target[key] || {}, source[key]);
      } else {
        target[key] = source[key];
      }
    }
    return target;
  };

  return jsonArray.reduce((acc, item) => {
    if (item.value && item.isDotNotation) {
      acc = deepMerge(acc, item.value);
    }
    return acc;
  }, {});
};

export const getFinalValueInDotNotation = (value: any): any => {
  if (typeof value !== "object" || value === null) {
    return value
  }
  const keys = Object.keys(value)
  if (keys.length === 1) {
    return getFinalValueInDotNotation(value[keys[0]])
  }
  return value
}

const dotNotationToObject = (dotNotation: string, value: any, baseObject: any = {}) => {
  const keys = dotNotation.split(".")
  return keys.reduceRight((acc, key, index) => {
    if (index === keys.length - 1) {
      if (typeof baseObject[key] === "object" && !Array.isArray(baseObject[key])) {
        return { ...baseObject[key], [key]: value }
      }
      return { [key]: value }
    }
    return { [key]: acc }
  }, value)
}

export const apply_value_filter_helper = (_data: IFilterData, state: any) => {
  const { isDotNotation, field, value } = _data

  if (isDotNotation) {
    return {
      ..._data,
      value: dotNotationToObject(field, value, parsed_filters_on_search(state)),
    }
  }
  return {
    ..._data,
    value,
  }
}
