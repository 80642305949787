import { Env, MultiClient } from "config"
import logo_entel_white from "assets/img/clients/entel-white.svg"
import logo_wom_white from "assets/img/clients/wom-white.png"
import { setPreRenderedAction } from "store/actions/publicActions"
import AuthContext from "store/context/PublicContext"
import { useContext } from "react"

const usePreRender = () => {
  const { loadedPreRender, dispatch } = useContext(AuthContext)
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  const handle_prerendered_action = (type: boolean) => {
    dispatch(setPreRenderedAction(type))
  }

  const get_client_color = (): string => {
    const clients: { [k: string]: string } = {
      core: "core",
      entel: "entel",
      entelperu: "entel",
      wom: "wom",
    }
    if (!_client) return clients?.core
    return clients.hasOwnProperty(_client) ? clients[_client] : clients.core
  }

  const get_generic_logo = (): string => {
    const clients: { [k: string]: string } = {
      core: "",
      entel: logo_entel_white,
      entelperu: logo_entel_white,
      wom: logo_wom_white,
    }
    if (!_client) return clients?.core
    return clients.hasOwnProperty(_client) ? clients[_client] : clients.core
  }

  return {
    _generic_logo: get_generic_logo(),
    _color: get_client_color(),
    loadedPreRender,
    handle_prerendered_action,
  }
}

export default usePreRender
