import React, { useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover";
import { Button } from "../ui/button";
import { format, isBefore } from "date-fns";
import { es } from "date-fns/locale";

interface RangePopoverProps {
  value: DateRange | undefined;
  onChange: (value: DateRange | undefined) => void;
}

export function RangePopover({ value, onChange }: RangePopoverProps) {
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState<Date | null>(value?.from || null);
  const [to, setTo] = useState<Date | null>(value?.to || null);
  const [enteredTo, setEnteredTo] = useState<Date | null>(value?.to || null);

  React.useEffect(() => {
    setFrom(value?.from || null);
    setTo(value?.to || null);
    setEnteredTo(value?.to || null);
  }, [value]);

  function isSelectingFirstDay(fromDate: Date | null, day: Date) {
    return (
      !fromDate ||
      (to && from && day >= from && day <= to) ||
      (fromDate && isBefore(day, fromDate))
    );
  }

  function handleDayClick(day: Date) {
    if (!from || (from && to)) {
      setFrom(day);
      setTo(null);
      setEnteredTo(null);
    } else if (isSelectingFirstDay(from, day)) {
      setFrom(day);
      setTo(null);
      setEnteredTo(null);
    } else {
      setTo(day);
      setEnteredTo(day);
      setOpen(false);
      onChange({
        from: from < day ? from : day,
        to: from < day ? day : from,
      });
    }
  }

  function handleDayMouseEnter(day: Date) {
    if (!from || to) return;
    if (isSelectingFirstDay(from, day)) {
      setEnteredTo(null);
    } else {
      setEnteredTo(day);
    }
  }

  const selectedDays: Date[] = [];
  if (from) {
    const fromTime = from.getTime();
    const toDay = to || enteredTo;
    if (toDay) {
      const toTime = toDay.getTime();
      const start = fromTime < toTime ? from : toDay;
      const end = fromTime < toTime ? toDay : from;
      if (start && end) {
        const day = new Date(start);
        while (day <= end) {
          selectedDays.push(new Date(day));
          day.setDate(day.getDate() + 1);
        }
      }
    } else {
      selectedDays.push(from);
    }
  }

  let label = "Seleccionar rango";
  if (from) {
    if (to) {
      const realFrom = from < to ? from : to;
      const realTo = from < to ? to : from;
      label = `${format(realFrom, "LLL dd, yyyy", { locale: es })} - ${format(
        realTo,
        "LLL dd, yyyy",
        { locale: es }
      )}`;
    } else {
      label = format(from, "LLL dd, yyyy", { locale: es });
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" onClick={() => setOpen(!open)}>
          {label}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 bg-white" onClick={(e) => e.stopPropagation()}>
        <DayPicker
          locale={es}
          numberOfMonths={2}
          onDayClick={handleDayClick}
          onDayMouseEnter={handleDayMouseEnter}
          selected={selectedDays}
          modifiers={{
            inRange: (day: Date) => {
              if (!from) return false;
              const finalTo = to || enteredTo;
              if (!finalTo) return day.getTime() === from.getTime();
              const start = from < finalTo ? from : finalTo;
              const end = from < finalTo ? finalTo : from;
              return day >= start && day <= end;
            },
            start: from || undefined,
            end: to || enteredTo || undefined,
          }}
          modifiersStyles={{
            inRange: { backgroundColor: "#c3e7fa" },
            start: {
              backgroundColor: "#c3e7fa",
              borderTopLeftRadius: "50%",
              borderBottomLeftRadius: "50%",
            },
            end: {
              backgroundColor: "#c3e7fa",
              borderTopRightRadius: "50%",
              borderBottomRightRadius: "50%",
            },
          }}
          styles={{
            months: { display: "flex", gap: "1rem", padding: "1rem" },
          }}
        />
      </PopoverContent>
    </Popover>
  );
}