import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationStock from "utils/yup/stock_schema"
import stock_values from "utils/forms_default/stock_values"
import { FormStock } from "components/FormEntities"
import useStockResources from "hooks/entities/useStockResources"
import { useNavigate, useParams } from "react-router-dom"
import { parsed_body_to_send_update, fill_form_from_data } from "components/FormEntities/FormStock/stock.config"
import Swal from "sweetalert2"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"

const EditScreen = () => {
  const { stock_id } = useParams()
  const navigate = useNavigate()
  const [stock, set_stock] = useState<any>()
  const { control, watch, errors, setValue, handleSubmit, reset } = useForm({
    defaultValues: stock_values,
    schema: schemaValidationStock,
  })
  const { data: data_stocks, search_osrm_action: search_osrm_action_stocks } = useSearchMiddlewareOSRM({
    entity: "stocks",
  })
  const { set_loading_app, put_update_stock_action } = useStockResources()

  useEffect(() => {
    search_osrm_action_stocks({ from: 0, size: 1000, filters: { id: stock_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_stocks?.records.length)) {
      const get_stock = async () => {
        let stock_from_req = data_stocks?.records[0]
        set_stock(stock_from_req)
      }
      get_stock()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_stocks])

  useEffect(
    () =>
      // RESET FORM TO FILL FIELDS
      stock && reset(fill_form_from_data(stock)),
    // eslint-disable-next-line
    [stock],
  )

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send_update(data)
      let res: any = await put_update_stock_action({ body, id: stock_id })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Stock actualizado",
          icon: "success",
        })
        navigate(`/stock/${stock_id}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de stock", href: "/stock" },
          { name: "Edición de stock", href: `/stock/${stock_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="Edición de stock" string_id={stock_id} />
        {/* <p>StockEditScreen</p> */}
      </div>
      <form className="w-full flex flex-col py-2 px-4 lg:px-10" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormStock control={control} errors={errors} watch={watch} setValue={setValue} edit />
      </form>
    </div>
  )
}

export default EditScreen
