import React, { useState } from "react";
import { Button } from "../ui/button";
import { FiFileText } from "react-icons/fi";
import { useApiServices } from "../../hooks/useApiService";
import { exportExcel } from "../../hooks/exportExcelService"; 
import type { BuildExportParamsFn } from "../../hooks/exportExcelService";
import { useToast } from "../../hooks/use-toast";

interface ExportExcelButtonProps {
  buildExportParams: BuildExportParamsFn;
}

export const ExportExcelButton: React.FC<ExportExcelButtonProps> = ({ buildExportParams }) => {
  const { fetchOrdersExport } = useApiServices();
  const { toast } = useToast(); 
  const [exporting, setExporting] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleExportClick = () => {
    exportExcel(buildExportParams, fetchOrdersExport, {
      setProgress: (p) => setProgress(p),
      onExportStart: () => setExporting(true),
      onExportFinish: () => setExporting(false),
      onNotifySuccess: (msg) => {
        toast({ variant: "default", title: "Exportación exitosa", description: msg });
      },
      onNotifyError: (msg) => {
        toast({ variant: "destructive", title: "Error exportando", description: msg });
      },
    });
  };

  return (
    <div>
      <Button
        onClick={handleExportClick}
        variant="outline"
        disabled={exporting}
        className="inline-flex items-center justify-center gap-2 bg-white text-black hover:bg-gray-100"
      >
        <FiFileText className="h-4 w-4" />
        {exporting ? "Exportando..." : "Exportar Excel"}
      </Button>

      {exporting && (
        <div className="mt-2 w-[200px] h-3 bg-gray-200 relative">
          <div
            className="h-3 bg-blue-500"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
};